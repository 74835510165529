.section-partners {
    padding: calcFluid(35, 55) 0;
    overflow: hidden;
    $this:&;
    &__header {
        margin-bottom: calcFluid(30, 60);
        display: flex;
        align-items: center;
    }
    &__title {
        @include font-32;
        font-weight: 700;
        color: var(--color-theme_text);
        margin-right: 15px;
    }
    &--two {
        padding-bottom: calcFluid(20, 50);
        .partners-slider {
            padding: 0;
            margin-bottom: 30px;
        }
        .partners-slider-arrows {
            width: 40%;
            margin: auto;
            position: static;
        }
        #{ $this }__title {
            margin-left: 0;
            margin-bottom: calcFluid(30, 60);
        }
    }
    &__wrap {
        position: relative;
    }
}

.partners-slider-wrap {
    position: relative;
}

.partners-slider {
    padding: 0 calcFluid(30, 50);
    .slick-track {
        display: flex;
    }
    .slick-slide {
        height: auto;
        > div {
            padding-left: 15px;
            padding-right: 15px;
            height: 100%;
        }
    }
    .slick-dots {
        bottom: auto;
        top: calcFluidP(20, 55);
    }
}

.partners-slide {
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: calcFluid(10, 18);
    max-height: 96px;
    border-radius: 5px;
    $this:&;
    &__img {
        max-width: 100%;
        max-height: 100%;
        filter: grayscale(1);
        transition: .3s;
    }
    &:hover {
        #{ $this }__img {
            filter: grayscale(0);
        }
    }
}

.partners-slider-arrows {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    pointer-events: none;
    $this:&;
    &__arrow {
        width: 30px;
        height: 60px;
        background: #fff;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .15s;
        pointer-events: all;
        &.next {
            margin-right: calcFluid(-10, 0);
            #{ $this }__svg {
                transform: rotate(180deg);
            }
        }
        &.prev {
            margin-left: calcFluid(-10, 0);
        }
        &:focus {
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1) !important;
        }
        &:hover {
            background-color: $main-color-primary;
            transition: .3s;
            #{ $this }__svg {
                fill: #fff;
                transition: .3s;
            }
        }
    }
    &__svg {
        width: 8px;
        height: 12px;
        fill: #333;
        transition: .15s;
    }
}

.partners {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: calcFluid(15, 30);
    column-gap: calcFluid(15, 30);
    &:not(:last-child) {
        margin-bottom: calcFluid(15, 30);
    }
}

.partners-hidden-block {
    display: none;
    width: 100%;
    margin-bottom: 30px;
}

.partners-collapse {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.partners-show-more {
    display: flex;
    align-items: center;
    justify-content: center;
    $this:&;
    &__text {
        font-weight: 500;
        @include font-18;
        text-decoration-line: underline;
        color: $main-color-secondary;
    }
    &__svg {
        width: 8px;
        height: 12px;
        transform: rotate(-90deg);
        fill: $main-color-secondary;
        display: block;
        margin-left: 18px;
        transition: .3s;
    }
    &.active {
        #{ $this }__svg {
            transform: rotate(90deg);
        }
    }
    &:hover {
        #{ $this }__text {
            text-decoration: none;
        }
    }
}

.partners-item {
    padding: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    $this:&;
    &__img {
        max-width: 100%;
        max-height: 100%;
        filter: grayscale(1);
        transition: .3s;
    }
    &:hover {
        #{ $this }__img {
            filter: grayscale(0);
        }
    }
}

@media(max-width: 1199px) {
    .partners-item {
        &__img {
            filter: grayscale(0);
        }
    }
    .partners-slider {
        padding: 0;
    }
    .partners-slider-arrows {
        display: none;
    }
}


@media(max-width: 991px) {
    .section-partners {
        &__header {
            flex-direction: column-reverse;
            align-items: flex-start;
        }
    }
}



@media(max-width: 767px) {
    .partners {
        grid-template-columns: repeat(2, 1fr);
    }
}