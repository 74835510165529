.footer {
    background-color: $main-color-secondary;
    &__wrap {
        display: flex;
        align-items: center;
        padding: 20px 0;
    }
    &__text {
        @include font-13;
        color: #D6D6D6;
        &:not(:first-child) {
            margin-left: 50px;
        }
        &.link {
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
}

.made-by {
    display: flex;
    align-items: center;
    margin-left: auto;
    &__svg {
        width: 93px;
        height: 16px;
        fill: #D6D6D6;
    }
    &__text {
        @include font-13;
        color: #D6D6D6;
    }
}


@media(max-width: 767px) {
    .footer {
        &__wrap {
            flex-direction: column;
            align-items: flex-start;
        }
        &__text {
            &:not(:first-child) {
                margin-left: 0px;
            }
            margin-bottom: 10px;
        }
    }
    .made-by {
        margin-left: 0;
    }
}