.section-slider {
    padding: calcFluid(25, 55) 0;
    &__wrap {
        position: relative;
    }
}

.section-slider-slider {
    border: 1px solid #E4E4E4;
    border-radius: 0 12px;
    overflow: hidden;
    > .section-slider-slide {
        &:not(:first-child) {
            display: none;
        }
    }
}

.section-slider-slide {
    position: relative;
    z-index: 0;
    &__wrap {
        padding: calcFluid(35, 100) 15px calcFluid(40, 140) calcFluid(20, 100);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        z-index: 1;
    }
    &__title {
        @include font-32;
        font-weight: 700;
        color: var(--color-theme_text);
        display: block;
        margin-bottom: 15px;
    }
    &__text {
        @include font-16;
        color: var(--color-theme_text);
        display: block;
        margin-bottom: calcFluid(23, 36);
    }
    &__button {
        display: inline-flex;
        padding: 12px 40px;
    }
    &__img {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
        width: 70%;
        background-repeat: no-repeat;
        background-size: cover;
    }
    &__img-gradient {
        background-image: linear-gradient(var(--direction), transparent, var(--color-theme));
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }
}

.section-slider-controls {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    $this:&;
    &__item {
        background-color: #fff;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 60px;
        pointer-events: all;
        transition: background-color .3s;
        &--prev {
            margin-left: calcFluid(0, 10);
        }
        &--next {
            margin-right: calcFluid(0, 10);
            #{ $this }__arrow {
                transform: rotate(180deg);
            }
        }
        &:hover {
            background-color: $main-color-primary;
            #{ $this }__arrow {
                fill: #fff;
            }
        }
        &:focus {
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        }
    }
    &__arrow {
        display: block;
        width: 12px;
        height: 15px;
        fill: #333;
        transition: fill .3s;
    }
}

:root {
    --direction: to left;
}
@media(max-width: 767px) {
    :root {
        --direction: to top;
    }

    .section-slider-slider {
        border-radius: initial;
    }

    .section-slider-slide {
        &__wrap {
            padding-bottom: 0;
        }
        &__button {
            width: 100%;
            margin-bottom: 20px;
        }
        &__img {
            position: static;
            height: calcFluid(170, 300, 320, 767);
            width: 100%;
        }
    }
}

@media(max-width: 575px) {
    .section-slider-controls {
        display: none;
    }
}