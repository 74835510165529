.header {
    // background-color: var(--color-theme);
    // position: sticky;
    top: 0;
    z-index: 10;
    // background: white;
}

.header-top {
    padding: 5px 0;
    height: 70px;
    display: flex;
    align-items: center;
    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__left {
        display: flex;
        align-items: center;
    }
    &__desc {
		@include font-13;
        color: #929292;
    }
    &__right {
        display: flex;
        align-items: center;
    }
    &__button {
        padding: calcFluid(7, 14, 767, 1920) calcFluid(20, 29, 767, 1920);
    }

    &__messager {
        display: none;
    }
}

.logo {
    display: block;
    width: var(--width);
    height: var(--height);
    margin-right: calcFluid(10, 27, 767, 1920);
    &__img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.header-contact {
    display: flex;
    align-items: center;
    margin-right: calcFluid(15, 30, 767, 1920);
    &__left {
        width: 15px;
        height: 15px;
        margin-right: 10px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__svg {
        width: calcFluid(13, 15, 767, 1920);
        height: calcFluid(13, 15, 767, 1920);
        // fill: $main-color-secondary;
        fill: #333;
    }
    &__text {
        @include font-13;
        color: $color-text;
    }
    &__phone {
        @include font-14;
        color: $color-text;
        display: block;
        transition: color .3s;
        &:not(:last-child) {
            margin-bottom: calcFluid(2, 5, 767, 1920);
        }
        &:hover {
            color: $main-color-primary;
        }
    }
}

.header-nav {
    padding: 13px 0;
    ul {
        display: flex;
        li {
            &:not(:last-child) {
                margin-right: calcFluid(20, 58, 767, 1920);
            }
            a {
                font-size: calcFluid(13, 14);
	            line-height: calcFluid(18, 22);
                color: #333;
                display: block;
                transition: color .3s;
                &:hover {
                    color: $main-color-primary;
                }
            }
        }
    }
}

.mobile-menu-open {
    display: none;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    height: 18px;
    $this:&;
    &__button {
        width: 18px;
        height: 2px;
        background-color: $main-color-primary;
        &:before, &:after {
            content: "";
            position: absolute;
            display: block;
            width: 100%;
            left: 0;
            height: 2px;
            background-color: $main-color-primary;
            transition: .3s;
        }
        &:before {
            bottom: 0;
        }
        &:after {
            top: 0;
        }
    }
    &.active {
        #{ $this }__button {
            background-color: transparent;
            &:before {
                transform: rotate(45deg);
                top: 0;
                margin: auto;
            }
            &:after {
                transform: rotate(-45deg);
                bottom: 0;
                margin: auto;
            }
        }
    }
}

.mobile-menu {
    position: fixed;
    top: 50px;
    right: 0;
    width: 100%;
    height: calc(100% - 50px);
    z-index: 600;
    background-color: #fff;
    padding-bottom: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    left: 100%;
    transition: .3s linear;
	&__wrapper {
	}
	&.opened, &.active, .nav_close.opened{
		left: 0;
		display: block;
	}
	li {
        padding: 0 15px;
        border-bottom: 1px solid #EAEAEA;
		a {
            display: block;
			padding: 15px 0;
			font-size: 15px;
			line-height: 19px;
			color: #333;
		}
    }
    .header-contact {
        padding: 15px 25px;
        border-bottom: 1px solid #eaeaea;
    }
    .messagers {
        padding: 15px 25px;
    }
}

.header-bottom {
    background-color: #F5F5F5;
    height: 50px;
    display: flex;
    align-items: center;
    $this:&;
    &.scroll {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 10;
        .messager--phone {
            display: flex;
        }
        #{ $this }__logo {
            display: block;
        }
        .header-nav ul li:not(:last-child) {
            margin-right: calcFluid(20, 30, 992, 1920);
        }
    }
    &__wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__logo {
        display: none;
    }
}

.messagers {
    display: flex;
    &:not(:last-child) {
        margin-right: 20px;
    }
}

.messager {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calcFluid(23, 30);
    height: calcFluid(23, 30);
    border-radius: 5px;
    padding: 5px;
    &:not(:first-child) {
        margin-left: 16px;
    }
    &--viber {
        background-color: #854CA4;
    }
    &--whatsapp {
        background-color: #00D04C;
    }
    &--telegram {
        background-color: #00A2E7;
    }
    &--phone {
        background-color: $main-color-secondary;
        display: none;

        .messager__svg {
            fill: white;
        }
    }
    &__svg {
        fill: #fff;
        display: block;
        width: 100%;
        height: 100%;
    }
}

@media(max-width: 991px) {
    main {
        padding-top: 50px;
    }

    .header {
        border-bottom: 1px solid #F5F5F5;
        height: 50px;
        position: fixed;
        z-index: 10;
        background-color: #fff;
        top: 0;
        left: 0;
        right: 0;
    }

    .header-top {
        height: 100%;
        display: flex;
        align-items: center;
        &__right {
            display: none;
        }

        .header-top__messager {
            margin-left: auto;
            margin-right: 13px;
            display: flex;
            width: 21px;
            height: 21px;
        }
    }
    .mobile-menu-open {
        display: flex;
    }

    .header-bottom {
        display: none;
    }
}


@media(max-width: 767px) {
    .logo {
        width: var(--width-m);
        height: var(--height-m);
    }
}
