.section-about-company {
    background: #F5F5F5;
    padding: calcFluid(25, 55) 0;
    &__wrap {
        display: flex;
        justify-content: space-between;
        position: relative;
    }
    &__title {
        font-weight: 700;
        @include font-32;
        color: var(--color-theme_text);
        display: block;
        margin-bottom: calcFluid(15, 25);
    }
    &__text {
        max-height: 291px;
        padding-right: 20px;
        p {
            @include font-16;
            color: var(--color-theme_text);
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }
    &__left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 47%;
        max-width: 470px;
        margin-right: calcFluid(30, 90, 767, 1920);
    }
    &__right {
        width: 53%;
    }
    &__img {
        width: 100%;
        height: auto;
        object-fit: contain;
        display: block;
        border-radius: 5px;
    }
    &__video-wrap {
        position: relative;
        width: 100%;
        height: 100%;
        // padding-bottom: 56.25%;
        // min-height: calcFluid(160, 400, 320, 767);
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

@media(max-width: 1199px) {

}
@media(max-width: 767px) {
    .section-about-company {
        &__wrap {
            flex-direction: column;
        }
        &__left {
            width: 100%;
            padding-top: 0;
            margin-bottom: 30px;
        }
        &__right {
            width: 100%;
            padding-top: 0;
            margin-bottom: 30px;
        }
        &__video-wrap {
            min-height: calcFluid(160, 400, 320, 767);
        }
    }
}