
.button {
	max-width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	background-color: transparent;
	cursor: pointer;
	position: relative;
	border-radius: 5px;
	user-select: none;
}

.button-orang {
	background-color: $main-color-primary;
	border: 1px solid $main-color-primary;
	transition: background-color .3s;
	$this:&;
	&__text {
		@include font-15;
		font-weight: 700;
		color: #fff;
		transition: color .3s;
	}
	&:hover {
		background-color: #fff;
		#{ $this }__text {
			color: $main-color-primary;
		}
	}
}

.button-blue {
	background-color: $main-color-secondary;
	border: 1px solid $main-color-secondary;
	transition: background-color .3s;
	$this:&;
	&__text {
		@include font-15;
		font-weight: 700;
		color: #fff;
		transition: color .3s;
	}
	&:hover {
		background-color: #Fff;
		#{ $this }__text {
			color: $main-color-secondary;
		}
	}
}

.button-white {
	background-color: #fff;
	border: 1px solid #fff;
	transition: background-color .3s;
	$this:&;
	&__text {
		@include font-15;
		font-weight: 700;
		color: $main-color-primary;
		transition: color .3s;
	}
	&:hover {
		background-color: $main-color-primary;
		#{ $this }__text {
			color: #fff;
		}
	}
}

