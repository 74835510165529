.section-banner {
    position: relative;
    padding: calcFluid(30, 90) 0 calcFluid(40, 125);
    $this:&;
    .section-bg {
        z-index: 1;
    }
    &__wrap {
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    &__left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    &__title {
        @include font-45;
        font-weight: 700;
        color: #333;
        display: block;
        max-width: calcFluid(290, 570);
        margin-bottom: calcFluid(10, 20);

        b{
            background-color: var(--secondary);
        }

    }
    &__desc {
        @include font-18;
        color: #333;
        display: block;
        max-width: calcFluid(330, 370, 992, 1920);
        margin-bottom: calcFluid(20, 40);
    }
    &__button {
        padding: calcFluid(7, 14, 767, 1920) calcFluid(20, 29, 767, 1920);

        &--mobile {
            display: none;
        }
    }
    .section-banner__img {
        display: none;
    }
    &.dark {
        #{ $this }__title {
            color: #fff;
        }
        #{ $this }__desc {
            color: #fff;
        }
    }
    &.light {
        #{ $this }__title {
            color: #333;
        }
        #{ $this }__desc {
            color: #333;
        }
        .custom-timer {
            &__text,
            &__seporator {
                color: #333;
            }
        }
    }
}

.modal-form {
    background-color: #fff;
    padding: calcFluid(20, 40) calcFluid(15, 33);
    max-width: 100%;
    width: calcFluid(290, 370, 320, 1920);
    border-radius: 5px;
    &__wrap {
        margin-bottom: 13px;
        .field {
            $this:&;
            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }
    }
    &__title {
        @include font-24;
        font-weight: 700;
        text-align: center;
        color: #333;
        display: block;
        margin-bottom: 14px;
    }
    &__desc {
        @include font-16;
        text-align: center;
        color: #333;
        display: block;
        margin-bottom: 22px;
    }
    &__button {
        width: 100%;
        height: 50px;
        margin-bottom: 10px;
    }
    .personal {
        text-align: center;
    }
}

.custom-modal-body {
    .modal-form {
        padding: 0;
    }
}

.form-success {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &__svg {
        fill: #1a2b67;
        width: 80px;
        height: 80px;
        margin-bottom: 20px;
    }
    &__text {
        @include font-22;
        font-weight: 700;
        color: #333;
        text-align: center;
    }
}

.custom-timer {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    padding-bottom: calcFluid(19, 34);
    &:not(:last-child) {
        margin-bottom: calcFluid(15, 30);
    }
    user-select: none;
	&__wrapper {
        display: flex;
        align-items: center;
	}
	&__part {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }
    &__seporator {
        font-size: 30px;
        line-height: 1;
        color: #fff;
        margin: 0 calcFluid(5, 11);
    }
	&__int {
        font-size: calcFluid(20, 32);
        line-height: calcFluid(24, 37);
        color: #fff;
		font-weight: 700;
        display: flex;
        > span {
            background: $main-color-primary;
            border-radius: 4px;
            padding: calcFluid(5, 12);
            display: block;
            &:not(:last-child) {
                margin-right: calcFluid(2, 5);
            }
        }
	}
	&__text {
        font-size: calcFluid(12, 18);
        line-height: calcFluid(14, 24);
		font-weight: 700;
        color: #fff;
        position: absolute;
        left: 0;
        right: 0;
        top: calcFluidP(5, 10, 320, 1920, 100);
        text-align: center;
	}
}

@media (max-width : 767px) {
    .section-banner {
        $this:&;

        &__wrap {
            flex-direction: column;
            align-items: center;
        }
        &__left {
            margin-bottom: 25px;
        }

        &--mobile-view {
            .custom-timer,
            .section-bg
            {
                display: none;
            }

            .section-banner__button {
                display: none;

                &--mobile {
                    margin-bottom: 38px;
                    display: flex;
                    width: 100%;
                    height: 50px;
                    text-align: center;
                }
            }

            .section-banner__img {
                display: block;
                align-self: center;
                object-fit: contain;
                width: calcFluid(151, 361, 320, 767);
                height: calcFluid(127, 304, 320, 767);
            }

            &.dark {
                #{ $this }__title {
                    color: #333;
                }
                #{ $this }__desc {
                    color: #333;
                }
                .custom-timer {
                    &__text,
                    &__seporator {
                        color: #333;
                    }
                }
            }
        }
    }

}
