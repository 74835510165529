.section-advantages {
    padding-top: calcFluid(25, 45);
    padding-bottom: calcFluid(25, 45);
    $this:&;
    &__header {
        margin-bottom: calcFluid(20, 40);
        display: flex;
        align-items: center;
    }
    &__title {
        @include font-32;
        font-weight: 700;
        color: var(--color-theme_text);
        margin-right: 15px;
    }

    &__wrapper {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        row-gap: calcFluid(10, 30);
        column-gap: calcFluid(10, 30);
        &:not(:last-child) {
            margin-bottom: calcFluid(30, 80);
        }
    }
    &__wrap {
        position: relative;
        display: flex;
    }
    &--two {
        .vertical-title__line {
            height: 80px;
        }
        #{ $this }__title {
            max-width: 240px;
            margin-right: 30px;
        }
    }
    &--three {
        #{ $this }__wrapper {
            grid-template-columns: repeat(3, 1fr);
        }
        .advantage-block {
            flex-direction: row;
            align-items: center;
            &__img-wrap {
                margin-right: calcFluid(15, 25);
                margin-bottom: 0;
            }
            &__text {
                text-align: start;
            }
        }
    }
}

.advantage-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__img-wrap {
        display: block;
        width: calcFluid(45, 85);
        height: calcFluid(45, 85);
        padding: calcFluid(8, 18);
        margin-bottom: calcFluid(9, 18);
        border-radius: 50%;
        background-color: $main-color-primary;
        position: relative;
        flex-shrink: 0;
        &:after {
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            width: calcFluid(10, 19);
            height: calcFluid(10, 19);
            background-color: $main-color-secondary;
        }
    }
    &__img {
        display: block;
        width: 100%;
        height: 100%;
    }
    &__text {
        @include font-18;
        color: var(--color-theme_text);
        text-align: center;
    }
}

.advantages {
    display: flex;
    position: relative;
    cursor: pointer;
    user-select: none;
    &__item {
        flex-shrink: 0;
        width: 33.3334%;
    }
}

.advantages-simplebar {
    width: 100%;
    padding-bottom: calcFluid(20, 50);
}

.simplebar-track {
    &.simplebar-horizontal {
        height: 6px;
        &:before {
            content: "";
            position: absolute;
            top: 2px;
            left: 0;
            right: 0;
            bottom: 2px;
            background-color: #F5F5F5;
        }
        .simplebar-scrollbar {
            height: 100%;
            top: 0;
            cursor: pointer;
            &:before {
                left: 0;
                right: 0;
                opacity: 0.5;
                cursor: pointer;
            }
            &.simplebar-visible {
                &:before {
                    opacity: 1;
                }
            }
        }
    }
}



@media(max-width: 991px) {
    .section-advantages {
        &__wrapper {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media(max-width: 767px) {
    .section-advantages {
        $this:&;
        &__wrap {
            flex-direction: column;
        }
        &--two {
            #{ $this }__title {
                max-width: 100%;
                width: 100%;
                margin-bottom: 20px;
                margin-right: 0;
            }
            .vertical-title__line {
                height: 9px;
            }
        }
        &--three {
            #{ $this }__wrapper {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}

@media(max-width: 575px) {
    .section-advantages {
        $this: &;
        &__wrapper {
            grid-template-columns: repeat(1, 1fr);
            .advantage-block {
                flex-direction: revert;
                align-items: center;
                justify-content: flex-start;
                &__img-wrap {
                    margin-bottom: 0;
                    margin-right: 15px;
                }
                &__text {
                    text-align: start;
                }
            }
        }
        &__header {
            flex-direction: column-reverse;
            align-items: flex-start;
        }
        &__title {
            margin-right: 0;
        }
        &--three {
            #{ $this }__wrapper {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }
    .advantage-block {
        &__text {
            br {
                display: none;
            }
        }
    }
    .advantages {
        &__item {
            width: 50%;
        }
    }
}