// Общее для трёх и четырех колонок
.section-files {
    padding: calcFluid(25, 55) 0;
    &__wrap {
        position: relative;
    }
    &__title {
        font-weight: 700;
        @include font-32;
        color: var(--color-theme_text);
        display: block;
        margin-bottom: calcFluid(20, 40);
    }
	.files {
		display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: calcFluid(15, 30);
        .file {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: calcFluid(15, 20) calcFluid(15, 25) calcFluid(15, 25);
            transition: $transition;
            min-height: calcFluid(100, 130);
            border: 1px solid #D6D6D6;
            border-radius: 5px;
        }
        .file__title {
            color: var(--color-theme_text);
            @include font-18;
            font-weight: bold;
            transition: $transition;
            margin-bottom: calcFluid(15, 35);
        }
        .file:hover {
            background-color: var(--primary);
            border-color: var(--primary);
        }
        .file:hover .file__title {
            color: #fff;
        }
        .file-info {
            display: flex;
            align-items: flex-end;
        }
        .file-info__arrow {
            width: 22px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            transition: $transition;
        }
        .file-info__arrow > svg {
            width: 100%;
            height: 100%;
            fill: var(--secondary);
            transition: $transition;
        }
        .file:hover .file-info__arrow > svg {
            fill: #fff;
        }
        .file-info__size {
            color: #7B7B7B;
            font-weight: 400;
            @include font-16;
            transition: $transition;
        }
        .file:hover .file-info__size {
            color: #fff;
        }
	}

}
@media(max-width: 991px) {
	.section-files {
        .files {
            grid-template-columns: 1fr 1fr;
        }
    } 
}
@media(max-width: 575px) {
	.section-files {
        .files {
            grid-template-columns: 1fr;
        }
    }
}

// Только для четырёх колонок
@media (min-width: 1200px) {
    .section-files {
        .files--col-4 {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: calcFluid(15, 30);
            .file {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 15px;
                transition: $transition;
                min-height: calcFluid(100, 113);
                border: 1px solid #D6D6D6;
                border-radius: 5px;
            }
            .file__title {
                color: var(--color-theme_text);
                @include font-13;
                font-weight: bold;
                transition: $transition;
                margin-bottom: 20px;
            }
            .file:hover {
                background-color: var(--primary);
                border-color: var(--primary);
            }
            .file:hover .file__title {
                color: #fff;
            }
            .file-info {
                display: flex;
                align-items: flex-end;
            }
            .file-info__arrow {
                width: 15px;
                height: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                transition: $transition;
            }
            .file-info__arrow > svg {
                width: 100%;
                height: 100%;
                fill: var(--secondary);
                transition: $transition;
            }
            .file:hover .file-info__arrow > svg {
                fill: #fff;
            }
            .file-info__size {
                color: #7B7B7B;
                font-weight: 400;
                @include font-12;
                transition: $transition;
            }
            .file:hover .file-info__size {
                color: #fff;
            }
        }
    }
};