.custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 100vw;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: auto;
    z-index: 100;
    display: none;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    $this:&;
    &.modal-opens,
    &.modal-open {
        display: flex;
    }
    &__wrapper {
        width: calcFluid(290, 370, 320, 1920);
        max-width: calc(100% - 30px);
		margin: auto;
        background-color: #fff;
		opacity: 0;
		border-radius: 3px;
		overflow: hidden;
	}
    &.modal-text {
        #{ $this }__wrapper {
            width: 900px;
        }
    }
}

.custom-modal-header {
	padding: calcFluid(8, 12, 320, 1920) calcFluid(8, 12, 320, 1920) calcFluid(15, 24, 320, 1920);
	&__wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: calcFluid(15, 30, 320, 1920) 0;
	}
}

.custom-modal-body {
	padding: 0 calcFluid(15, 30, 320, 1920) calcFluid(25, 50, 320, 1920);
}

.close-modal {
    display: block;
    cursor: pointer;
    width: calcFluid(10, 15, 320, 1920);
    height: calcFluid(10, 15, 320, 1920);
    margin-left: auto;
    background-color: transparent;
    border: none;
    $this:&;
    &__svg {
        width: 100%;
        height: 100%;
        fill: #888;
        transition: .2s;
    }
    &:hover {
        #{ $this }__svg {
            fill: $main-color-primary;
        }
    }
}



.modal-form {
    $this:&;
    &.loading {
        .loading-animate-wrap {
            display: flex;
        }
    }
}

@media(max-width: 575px) {

}