/*	Reset
---------------------------------------*/
*  {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
img, button {
	user-select: none;
}
*:focus, *:focus *{
	// box-shadow: none !important;
	outline: none !important;
}
.custom-control-input:focus~.custom-control-label::before{
	box-shadow: none !important;
}
html {
	min-height: 100%;
	height: 100%;
	&.opened{
		overflow: hidden;
		position: fixed;
	}
}
body {
	min-width: 320px;
	height: 100%;
	margin: 0;
	font-family: 'Roboto', sans-serif;
	&.opened {
		overflow: hidden;
		height: 100%;
	}
}

ol, ul  { 
	padding: 0;
	margin: 0;
}
li  {
	list-style-type: none;
}
.h1, .h2, .h3, .h4, h1, h2, h3, h4, p  {
	line-height: normal;
	margin: 0;
	padding: 0;
}
a:focus, button:focus, input:focus, textarea:focus  {
	outline: 0;
	text-decoration: none;
}
a  {
	text-decoration: none;
	&:hover  {
		text-decoration: none;
	}
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}


// General styles
.container {
	width: calcFluid(1100, 1200, 1200, 1920);
	max-width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}

.section-bg {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--color);
        z-index: 1;
    }
    &__img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.color-primary {
	color: $main-color-primary;
}

.color-secondary {
	color: $main-color-secondary;
}

.bg-color-primary {
	background-color: $main-color-primary;
}

.bg-color-secondary {
	background-color: $main-color-secondary;
}
// Color site
.color-theme{

	// General

	main{

		& > section:not(.section-action){

			background-color: var(--color-theme);
			
		}

	}

	.header{
		// background-color: var(--color-theme);
	}


	// Section

	.section-banner{

		.modal-form{

			// background-color: var(--color-theme);

			label{

				input{

					// background-color: var(--color-theme_text);

				}

			}

		}

	}

	.section-slider-slide{

		background-color: var(--color-theme) !important;

	}

	.section-action {
		&::before {
			background-color: #FAFAFA;
		}
	}

	
	// Misc

	.requisite{

		&__left-left{

			background-color: var(--color-theme);

		}

	}

	.card-service-type-two{

		&__header{

			background-color: var(--color-theme);
		}

	}	

}

.color-theme-dark{
	.button-orang,
	.button-white {
		&:hover {
			border: 1px solid white !important;
		}
	}

	// General
	.header-contact{

		&__phone, &__svg, &__text{

			color: #fff;
			fill: #fff;
		}
	}

	.header-top{
		background-color: var(--color-theme);

		@media(max-width: 1200px){
			background-color: #333;
		}

		&__desc{
			color: #fff;
		}

		&__button{
			@include dark-button_mod-2;
		}

	}

	.header-bottom{
		background-color: var(--primary);
		li a{
			color: #fff !important;
		}

	}

	.footer{
		background-color: #3E3E3E;
	}

	input{
		background-color: transparent;
		color: #fff;
	}

	.slick{
		&-arrow{
			&:hover{
				background-color: var(--secondary);
				svg{
					fill: #fff;
				}
			}
		}
	}

	.mobile-menu{
		background-color: #191919;
		a{
			color: #fff;
		}

		li{
			border-bottom: 1px solid #2E2E2E;
		}

		.header-contact{
			border-bottom: 1px solid #2E2E2E;
		}

	}


	// Section
	.section-action{
		.section-action {
			&::before {
				background-color: #191919;
			}
		}

		a,p{
			color: #fff;
		}

		&__button{
			@include dark-button_mod-1;
		}

	}

	.section-about-company{
		// border-top: 1px solid var(--primary);
	}

	.section-banner{
		&__title{
			b{
				color: #fff !important;
			}
		}

		&__button{
			@include dark-button_mod-1;
		}

		.modal-form{
			background-color: #333;

			&__title{
				color: #fff;
			}

			&__desc{
				color: #C8C8C8;
			}

			&__button{
				@include dark-button_mod-1
			}
			.personal {
				color: #fff;
				a {
					color: #fff;
				}
			}
		}
	}

	.form-success {
		&__svg {
			fill: #fff;
		}

		&__text {
			color: #fff;
		}
	}

	.section-slider-slide{
		&__title{
			color: #fff;
		}

		&__text{
			color: #C8C8C8;
		}

		&__button{
			@include dark-button_mod-1;
		}

	}

	.section-gallery{
		&__title{
			color: #fff;
		}

		.gallery-show-more__text {
			color: white;
		}

		.gallery-show-more__svg {
			fill: white;
		}
	}

	.section-requisites{
		&-copy{
			span {
				color: #A1A1A1;
			}
			
			svg{
				fill: #A1A1A1;
			}
		}

		.button{
			@include dark-button_mod-2;
		}

	}

	.section-contacts{
		.button{
			@include dark-button_mod-1;
		}

		&--three {
			.city {
				&::after {
					background-color: #2E2E2E;
				}
			}
		}
	}

	.section-advantages{
		.simplebar-track{
			background-color: #2E2E2E !important;

			&::before{
				background-color: transparent;
			}

		}

	}

	.section-slider{
		&-slider{
			border: 1px solid #2E2E2E;
		}

		&-slide{
			&__img-gradient{
				background-image: linear-gradient(var(--direction), transparent, var(--color-theme)) !important;
			}
		}
	}

	.section-aplication-form {
		&__arrow {
			path {
				&:first-child{
					stroke: #fff;
				}

				&:nth-child(2){
					stroke: #fff;
					fill: #fff;
				}
			}
	
		}
	}

	.section-action {
		background-color: #191919;
	}

	// Misc
	.simplebar{
		&-scrollbar{
			&::before{
				opacity: 1 !important;
			}
		}

		&-track{
			background-color: rgba(255, 255, 255, 0.4) !important;
		}
	}

	.catalog-nav{
		&__item{
			color: #C8C8C8;

			&.active{

				color: #fff;
			}
		}
	}

	.vertical-title{
		&__text{
			color: #fff;
		}
	}

	.quote-svg{
		fill: #3b3b3b;
	}

	.schema-step{
		&__wrap{
			background-color: #191919;
			border: 1px solid #2E2E2E;

			&::after{
				border-left-color: #191919;
			}

			&::before{
				border-left-color: #2E2E2E;
			}
		}

		&__title{
			color: #fff;
		}

		&__text{
			color: #fff;
		}

	}

	.schema-last-step{
		&__ball{
			background-color: transparent;
		}

		&__title{
			color: #fff;
		}

		&__text{
			color: #fff;
		}

		&__wrap{
			background-color: #3E3E3E;

			&::after{
				border-bottom-color: #3E3E3E;
			}
		}

		&__button{
			@include dark-button_mod-1;
		}
	}

	.review-slide{
		&__wrap{
			background-color: #2E2E2E;

			* {
				color: #fff;
			}
		}
	}

	.employee{
		background-color: #191919;
		border: 1px solid #2E2E2E;

		&:hover {
			background-color:  #3E3E3E;
			border: 1px solid #3E3E3E;

			.employee__img-wrap {
				&::before {
					position: absolute;
					content: '';
					top: 0;
					left: 0;
					z-index: 2;
					width: 100%;
					height: 100%;
					background-color: rgba(51, 51, 51, 0.3);
				}
			}
		}

		&__body{
			* {
				color: #fff;
			}
		}
	}


	.FAQ__item{
		.FAQ__question{
			background-color: transparent;
			border: 1px solid rgba(255, 255, 255, 0.4);
		}

		.FAQ__question-text{
			color: #C8C8C8 !important;
		}

		.cc-pluse{
			&::after,&::before {
				background-color: #fff;
			}
		}

		&.active{
			.FAQ__question{
				background-color: #3E3E3E;
			}

			.FAQ__question-text{
				color: white !important;
			}
		}
	}

	.didnt-answer{
		background-color: #191919;
		border: 1px dashed #2E2E2E;

		&__title, &__text{
			color: #fff;
		}

		&__button{
			@include dark-button_mod-1;
		}
	}

	.requisite{
		&__left-left{
			color: rgba(255, 255, 255, 0.7);
		}

		&__left-right{
			border-bottom-color: rgba(255, 255, 255, 0.7);
		}

		&__right{
			color: #fff;
		}
	}

	.aplication-form{
		border: none;

		span, p{
			color: #fff;
		}
		.personal a {
			color: #fff;
		}

		&__head{
			background-color: #191919;
		}

		&__title{
			background-color: #2E2E2E;
		}

		&__body{
			background-color: #191919;
		}

		&-count{
			color: #6A6A6A;

			* {
				color: #6A6A6A !important;
			}
		}

		&-step{
			&__button{
				@include dark-button_mod-1;
			}

			&__submit{
				@include dark-button_mod-1;
			}

			&-answer{
				&__input{
					&:not([type=checkbox]):checked + .aplication-form-step-answer__check{
						background-color: #191919;
						border-color: var(--secondary);
					}
				}

				&__text{
					color: #C8C8C8 !important;
				}
			}
		}
	}

	.amount{
		background-color: #191919;

		input{
			color: #fff;
			background-color: #191919;
		}

	}

	.block-over-map{
		background-color: #191919;
	}

	.loading-animate-wrap{
		background-color: rgba(25, 25, 25, 0.8) !important;
	}

	.service-block{
		&__button {
			@include dark-button_mod-2;
		}
	}


	// Card
	.card-service{
		$this:&;
		
		&:hover{
			.card-service__title{
				color: #fff;
			}

			.card-service__wrap{
				&::after{
					background-color: #191919;
				}
			}
		}

		&__wrap{
			&::after{
				background-color: rgba(51, 51, 51, 0.7);
			}
		}

		&__insludes__item{
			&::before{
				background-color: var(--secondary) !important;
			}
		}

		&__button{
			@include dark-button_mod-1
		}

	}

	.card-service-type-two{
		$this:&;

		&:hover{
			.card-service-type-two__title{
				color: #fff;
			}

			.card-service-type-two__header{
				border: none;

				&:before{
					background-color: #191919;
				}
			}
	
			.card-service-type-two__svg{
				opacity: 0;
			}
		}

		&__header{
			&:before{
				background-color: var(--primary);
			}

		}

		&__hidden-block{
			background-color: #191919;
		}

		&__title{
			color: #fff;
		}

		&__insludes__item{
			&:before{
				background-color: var(--secondary);
			}
		}

		&__button{
			@include dark-button_mod-2;
		}

	}

	.card-service-type-three{
		$this:&;

		&__header{
			&:before{
				background-color: var(--primary);
			}
		}

		&__title{
			color: #fff;
		}

		&__svg{
			fill: #fff;
		}

	}

	.product-card{
		background-color: #191919;

		&-drop{
			background-color: #3E3E3E;

			p{
				color: #fff;
			}

			.size{
				color: #fff;
			}
		}

		&__title{
			color: #fff;
		}

		&__desc{
			color: #C8C8C8;
		}

		&__button{
			@include dark-button_mod-2;
		}

		&-price__new-price{
			color: #fff;
		}

		&-price__not-price{
			color: #fff;
		}

		&:hover {
			background-color: #3E3E3E;
		}
	}

	.product-markers {
		.product-marker {
			&.a {
				background-color: #438BCD;
			}
			&.b {
				background-color: #AD45ED;
			}
			&.c {
				background-color: #FEDFBA;
			}
		}
	}

	.product-card-price {
		&__new-price {
			color: #AD45ED;
		}
	}

	.show-more {
		display: flex;
		align-items: center;
		$this:&;
		&__text {
			color: white;
		}
		&__svg {
			fill: white;
		}
	}

	.sizes-picker {
		&__input {
			&:checked {
				+ .sizes-picker__text {
					color: white;
				}
			}
		}

		&__text {
			color: white;
		}
	}

	.color-picker {
		&__item {
			border: 2px solid white;
		}
	}

	.section-tariffs .tariff {
		&__header{
			background-color: #191919;
			border-bottom: 1px dashed #2E2E2E;
		}
		&__body{
			background-color: #191919;
		}
		&__cat{
			color: #C8C8C8;
		}
		&__name{
			color: #fff;
		}
		&__price{
			color: #fff;
		}
		&__button{
			&_mod-1{
				@include dark-button_mod-3;
			}
			&_mod-2{
				@include dark-button_mod-2;
			}
		}
		&-list{
			&__item{
				color: #C8C8C8;
				&::before{
					background-color: #fff;
				}
				&.important{
					color: #FFFFFF;
				}
			}
		}
		&:hover {
			.tariff__body {
				background-color: #2E2E2E;
			}
		}
	}
	.section-tariffs--one .tariff {
		border: 1px solid #2E2E2E;
		&.active, &:hover{
			border: 1px solid #2E2E2E;
		}
	}
	.section-tariffs--two .tariff {
		background-color: #191919;
		border-top-color: #2E2E2E;
		border-right-color: #2E2E2E;
		border-bottom-color: #2E2E2E;
		.tariff-list {
			border-right-color: #2E2E2E;
			border-left-color: #2E2E2E;
		}
		&:hover {
			background-color: #2E2E2E;
		}
	}
	.section-tariffs--three .tariff {
		border: 1px solid #2E2E2E;
		.tariff__col3 {
			border-top-color: #2E2E2E;
		}
		&.active, &:hover{
			border: 1px solid #2E2E2E;
		}
	}
	.section-tariffs--four .tariff {
		border: 1px solid #2E2E2E;
	}


	// Form
	.section-form{
		&--one{
			background-color: #191919 !important;
		}
	
		&--four{
			&::after{
				background-color: rgba(0, 0, 0, 0.7);
			}
		}

		.button{
			@include dark-button_mod-1;
		}

	}


	// Modal

	.custom-modal{
		&__wrapper{
			background-color: #191919;
		}

		input{
			background-color: transparent;
			color: #fff;
		}

		p, span {
			color: #fff;
		}
		.personal a {
			color: #fff;
		}

		.product-slider{
			&--for{
				border-color: #2E2E2E;
			}

			&--nav{
				.slick-slide{
					& > div{
						border-color: #2E2E2E;
					}
				}
			}
		}
		
		.modal-form{
			background-color: #191919;
		}

		.button{
			@include dark-button_mod-1;
		}

		.modal-product-total-cost{
			color: #fff;
		}

		.modal-product-info__tab {
			color: #fff;
		}

		.modal-product-form{
			background-color: transparent;
		}
	}

	// Partners
	.section-partners {
		.partners-show-more__text {
			color: white;
		}

		.partners-show-more__svg {
			fill: white;
		}
	}

	.partners-item,
	.partners-slide {
		background-color: white;
	}
}

picture {
	> img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}