.section-service {
    padding: calcFluid(25, 55) 0;
    &__header {
        margin-bottom: calcFluid(20, 40);
        display: flex;
        align-items: center;
    }
    &__title {
        @include font-32;
        font-weight: 700;
        color: var(--color-theme_text);
        margin-right: 15px;
    }

    &__wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        row-gap: calcFluid(10, 30);
        column-gap: calcFluid(10, 30);
        &:not(:last-child) {
            margin-bottom: calcFluid(30, 80);
        }
    }
}

.card-service {
    position: relative;
    $this:&;
    &__img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
    }
    &__wrap {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        border-radius: 0 0 5px 5px;
        overflow: hidden;
        padding: 20px calcFluid(15, 30);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        min-height: 100px;
        transition: all .15s;
        transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $main-color-secondary;
            opacity: .8;
            z-index: -1;
        }
    }
    &__title {
        @include font-24;
        font-weight: 700;
        color: #fff;
    }
    &__hidden-block {
        display: none;
        width: 100%;
    }
    &__insludes {
        padding-top: 20px;
        padding-bottom: 30px;
    }
    &__insludes__item {
        @include font-16;
        color: #fff;
        display: flex;
        align-items: baseline;
        &:not(:last-child) {
            margin-bottom: 10px;
        }
        &:before {
            content: "";
            width: 7px;
            height: 7px;
            background-color: $main-color-primary;
            border-radius: 50%;
            display: block;
            flex-shrink: 0;
            margin-right: 13px;
        }
    }
    &__button {
        width: 100%;
        height: 50px;
    }
    &.active {
        z-index: 2;
        #{ $this }__img {
            border-radius: 5px 5px 0 0;
        }
        #{ $this }__wrap {
            bottom: calc(var(--height) * -1);
            transition: all .3s;
            &:after {
                opacity: 1;
            }
        }
        #{ $this }__hidden-block {
            animation: .3s fadeIn;
            animation-fill-mode: forwards;
            display: block;
        }
    }
}
@keyframes fadeIn {
    0% {
        height: 0;
        visibility: hidden;
    }
    80% {
        visibility: hidden;
        opacity: 0;
    }
    100% {
        height: var(--height);
        opacity: 1;
        visibility: visible;
    }
}

.duble-title {
    display: flex;
    align-items: center;
    &__text {
        @include font-12;
        color: var(--color-theme_text);
    }
    &__line {
        margin-right: 13px;
        display: flex;
        align-items: center;
        &:before {
            content: "";
            display: block;
            width: 140px;
            height: 1px;
            background-color: $main-color-secondary;
        }
        &:after {
            content: "";
            display: block;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background-color: $main-color-secondary;
        }
    }
}

.card-service-type-two {
    position: relative;
    $this:&;
    &__header {
        background: #fff;
        border: 1px solid #D6D6D6;
        border-radius: 5px;
        padding: 20px calcFluid(20, 30);
        position: relative;
        z-index: 0;
        overflow: hidden;
        height: 100%;
        transition: .3s;
        &:before {
            content: "";
            position: absolute;
            top: calcFluidM(40, 70);
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            background-color: var(--primary);
            transform: rotate(-35deg);
            transform-origin: 100% 0%;
            transition: .15s;
        }
    }
    &__title {
        @include font-24;
        font-weight: 700;
        color: $main-color-secondary;
        transition: .3s;
    }
    &__svg {
        width: 10px;
        height: 16px;
        transform: rotate(-90deg);
        fill: #fff;
        margin-left: auto;
        display: block;
        position: absolute;
        right: 20px;
        bottom: 17px;
    }
    &__hidden-block {
        position: absolute;
        z-index: 2;
        left: 0;
        right: 0;
        top: 100%;
        width: 100%;
        background-color: $main-color-secondary;
        padding: 0 calcFluid(20, 30) calcFluid(20, 30);
        border-radius: 0 0 5px 5px;
        visibility: hidden;
        display: flex;
        flex-direction: column;
    }
    &__insludes {
        padding-top: calcFluid(10, 20);
        padding-bottom: calcFluid(20, 30);
    }
    &__insludes__item {
        @include font-16;
        color: #fff;
        display: flex;
        align-items: baseline;
        &:not(:last-child) {
            margin-bottom: 10px;
        }
        &:before {
            content: "";
            width: 7px;
            height: 7px;
            background-color: $main-color-primary;
            border-radius: 50%;
            display: block;
            flex-shrink: 0;
            margin-right: 13px;
        }
    }
    &__button {
        width: 100%;
        height: 50px;
    }
    &:hover, &.active {
        z-index: 2;
        #{ $this }__header {
            border-radius: 5px 5px 0 0;
            border-color: $main-color-secondary;
            &:before {
                transform: rotate(0deg);
                top: 0;
                transition: .3s;
            }
        }
        #{ $this }__svg {
            fill: $main-color-secondary;
        }
        #{ $this }__title {
            color: #fff;
        }
        #{ $this }__hidden-block {
            animation: .3s fadeIn;
            animation-fill-mode: forwards;
            z-index: 1;
        }
    }
}

.card-service-type-three {
    position: relative;
    $this:&;
    &__header {
        background: transparent;
        border: 1px solid #D6D6D6;
        border-radius: 5px;
        padding: 20px calcFluid(20, 30);
        position: relative;
        z-index: 0;
        overflow: hidden;
        height: 100%;
        transition: .3s;
        display: block;
        &:before {
            content: "";
            position: absolute;
            top: calcFluidM(40, 70);
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            // background-color: $main-color-secondary;
            background-color: var(--primary);
            transform: rotate(-35deg);
            transform-origin: 100% 0%;
            transition: .15s;
        }
        &.active {
            #{ $this }__svg {
                transform: rotate(90deg);
            }
        }
    }
    &__title {
        @include font-24;
        font-weight: 700;
        color: $main-color-secondary;
        transition: .3s;
    }
    &__svg {
        width: 10px;
        height: 16px;
        transform: rotate(-90deg);
        fill: #fff;
        margin-left: auto;
        display: block;
        position: absolute;
        right: 20px;
        bottom: 17px;
        transition: .3s;
    }
    .service-block {
        display: none;
    }
    &:hover, &.active {
        #{ $this }__header {
            border-radius: 5px 5px 0 0;
            border-color: $main-color-secondary;
            &:before {
                transform: rotate(0deg);
                top: 0;
                transition: .3s;
            }
        }
        #{ $this }__title {
            color: #fff;
        }
    }
}

.service-block {
    display: flex;
    &__left {
        width: calcFluid(280, 370, 767, 1920);
        margin-right: 30px;
        flex-shrink: 0;
    }
    &__right {
        flex-grow: 1;
    }
    &__img {
        display: block;
        width: 100%;
        height: auto;
        max-height: calcFluid(300, 400);
        border-radius: 5px;
        object-fit: cover;
    }
    &__title {

        display: block;

        @include font-24;
        font-weight: 700;

        color: var(--color-theme_text);
        
        margin-bottom: 20px;

    }
    &__button {
        height: calcFluid(40, 50);
        max-width: 270px;
    }
}

.service-block-insludes {
    padding-top: calcFluid(10, 20);
    padding-bottom: calcFluid(20, 30);
    &__item {
        @include font-16;
        color: var(--color-theme_text);
        display: flex;
        align-items: baseline;
        &:not(:last-child) {
            margin-bottom: 10px;
        }
        &:before {
            content: "";
            width: 7px;
            height: 7px;
            background-color: $main-color-primary;
            border-radius: 50%;
            display: block;
            flex-shrink: 0;
            margin-right: 13px;
        }
    }
}


@media(max-width: 991px) {
    .section-service {
        &__wrapper {
            grid-template-columns: repeat(2, 1fr);
        }
    }
    .card-service-type-three,
    .card-service-type-two {
        &__svg {
            width: 7px;
            height: 12px;
            right: 13px;
            bottom: 7px;
        }
    }
}

@media(max-width: 767px) {
    .service-block {
        flex-direction: column;
        &__left {
            width: 100%;
            margin-bottom: 20px;
            margin-right: 0;
        }
    }
}

@media(min-width: 1200px) {
    .card-service {
        $this:&;
        &:hover {
            z-index: 2;
            #{ $this }__img {
                border-radius: 5px 5px 0 0;
            }
            #{ $this }__wrap {
                bottom: calc(var(--height) * -1);
                transition: all .3s;
                &:after {
                    opacity: 1;
                }
            }
            #{ $this }__hidden-block {
                animation: .3s fadeIn;
                animation-fill-mode: forwards;
                display: block;
            }
        }
    }
}

@media(max-width: 575px) {
    .section-service {
        &__wrapper {
            grid-template-columns: repeat(1, 1fr);
        }
        &__header {
            flex-direction: column-reverse;
            align-items: flex-start;
        }
        &__title {
            margin-right: 0;
        }
    }
    .card-service {
        $this:&;
        &__wrap {
            min-height: 70px;
        }
        &.active {
            #{ $this }__wrap {
                position: static;
            }
        }
    }
    .card-service-type-two {
        $this:&;
        &:hover, &.active {
            #{ $this }__hidden-block {
                position: static;
            }
        }
        &__header {
            height: auto;
        }
    }
    .duble-title {
        margin-bottom: 10px;
        &__line {
            &:before {
                display: none;
            }
        }
    }
    .card-service-type-three {
        &__header {
            height: auto;
        }
    }
    .service-block {
        max-width: calcFluid(270, 360, 320, 575);
        margin: 0 auto;
        &.active {
            display: block;
        }
    }
}