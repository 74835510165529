.section-requisites {
    padding: calcFluid(25, 55) 0;
    overflow: hidden;
    $this:&;
    &__header {
        margin-bottom: calcFluid(30, 40);
        display: flex;
        align-items: center;
    }
    &__title {
        @include font-32;
        font-weight: 700;
        color: var(--color-theme_text);
        margin-right: 40px;
    }
    &__wrap {
        position: relative;
    }
}

.requisites {
    &__wrap {
        margin-bottom: calcFluid(30, 50);
    }
    &__button {
        max-width: 370px;
        height: 50px;
    }
}

.requisite {
    display: flex;
    align-items: flex-start;
    &:not(:last-child) {
        margin-bottom: 20px;
    }
    &__right {
        font-weight: 500;
        @include font-20;
        color: var(--color-theme_text);
    }
    &__left {
        display: flex;
        position: relative;
        padding-right: 60px;
        width: calcFluid(250, 400, 575, 1920);
        flex-shrink: 0;
    }
    &__left-left {
        @include font-16;
        color: var(--color-theme_text);
        background-color: #fff;
        z-index: 5;
    }
    &__left-right {
        height: 1px;
        border-bottom: 1px dashed #8191C2;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 2px;
        z-index: 1;
    }
}

.section-requisites-copy {
    display: flex;
    align-items: center;
    cursor: pointer;
    $this:&;
    &__svg {
        width: 18px;
        height: 24px;
        margin-right: 15px;
        fill: $main-color-secondary;
        transition: .3s;
    }
    &__text {
        @include font-15;
        font-weight: 700;
        color: #1A2B67;
        transition: .3s;
    }
    &:hover {
        #{ $this }__svg {
            fill: $main-color-primary;
        }
        #{ $this }__text {
            color: $main-color-primary;
        }
    }
}



@media(max-width: 575px) {
    .section-requisites {
        &__header {
            flex-direction: column;
            align-items: flex-start;
        }
        &__title {
            margin-right: 0;
            margin-bottom: 15px;
        }
    }
    .requisite {
        flex-direction: column;
        &__right {
            width: 100%;
        }
        &__left {
            width: 100%;
            margin-bottom: 5px;
            padding: 0;
        }
    }
}