.section-gallery {
    padding: calcFluid(25, 55) 0;
    &__title {
        @include font-32;
        font-weight: 700;
        color: var(--color-theme_text);
        margin-bottom: calcFluid(20, 40);
    }
    &__wrap {
        position: relative;
    }
}

.gallery-slider-wrap {
    position: relative;
}

.gallery-slider {
    margin: 0 -15px;
    .slick-slide {
        height: auto;
        > div {
            padding: 0 15px;
        }
    }
    .slick-dots {
        bottom: auto;
        top: calc(100% + 20px);
    }
}

.gallery-item {
    display: block;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: $whiteZoomIcon;
        background-size: 20px 20px;
        background-position: 90% 15%;
        background-repeat: no-repeat;
        transition: .3s;
    }
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $main-color-primary;
        opacity: 0;
        transition: .3s;
    }
    &:hover {
        &:after {
            background-position: 50% 50%;
            background-size: 35px 35px;
        }
        &:before {
            opacity: .5;
        }
    }
    &__img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.gallery-slider-arrows {
    position: absolute;
    top: 0;
    left: 10px;
    right: 10px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    pointer-events: none;
    $this:&;
    &__arrow {
        width: 30px;
        height: 60px;
        background: #fff;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .15s;
        pointer-events: all;
        &.next {
            #{ $this }__svg {
                transform: rotate(180deg);
            }
        }
        &:hover {
            background-color: $main-color-primary;
            transition: .3s;
            #{ $this }__svg {
                fill: #fff;
                transition: .3s;
            }
        }
    }
    &__svg {
        width: 8px;
        height: 12px;
        fill: #333;
        transition: .15s;
    }
}

.gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: calcFluid(15, 30);
    column-gap: calcFluid(15, 30);
    &:not(:last-child) {
        margin-bottom: calcFluid(15, 30);
    }
}

.gallery-hidden-block {
    display: none;
    margin-bottom: 30px;
}

.gallery-show-more {
    display: flex;
    align-items: center;
    justify-content: center;
    $this:&;
    &__text {
        font-weight: 500;
        @include font-18;
        text-decoration-line: underline;
        color: $main-color-secondary;
    }
    &__svg {
        width: 8px;
        height: 12px;
        transform: rotate(-90deg);
        fill: $main-color-secondary;
        display: block;
        margin-left: 18px;
        transition: .3s;
    }
    &.active {
        #{ $this }__svg {
            transform: rotate(90deg);
        }
    }
    &:hover {
        #{ $this }__text {
            text-decoration: none;
        }
    }
}

@media(max-width: 991px) {
}

@media(max-width: 767px) {
    .gallery {
        grid-template-columns: repeat(2, 1fr);
    }
}