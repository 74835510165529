.field {
    width: 100%;
    height: 50px;
	position: relative;
	display: block;
    $this:&;
	&.error {
		#{ $this }__input {
			border-color: red;
		}
	}
	&__input {
		height: 100%;
		width: 100%;
		background: #fff;
        border: 1px solid #BABABA;
		border-radius: 5px;
		display: block;
		font-size: 16px;
		line-height: 19px;
		padding-left: 20px;
		color: #333;
		padding-top: 15px;
	}
	&__text {
		font-size: 16px;
		line-height: 19px;
		color: #999;
		position: absolute;
		top: 15px;
		left: 20px;
		z-index: 1;
		cursor: text;
		transition: .3s;
	}
	&.active, &:hover {
		#{ $this }__text {
			color: #999;
			font-size: 12px;
			line-height: 14px;
			top: 7px;
			font-weight: 500;
		}
	}
	&--textarea {
		height: 150px;
		#{ $this }__input {
			padding-top: 20px;
		}
	}
}

.personal {
    font-size: 12px;
    line-height: 18px;
	color: #666;
	> a {
        color: #666;
		text-decoration: underline;
		transition: .3s;
		&:hover {
			text-decoration: none;
		}
	}
}