.section-employees {
    padding: calcFluid(25, 55) 0;
    overflow: hidden;
    $this:&;
    &__wrap {
        position: relative;
    }
    &__title {
        @include font-32;
        font-weight: 700;
        color: var(--color-theme_text);
        display: block;
        margin-bottom: calcFluid(20, 40);
    }
}

.employees-slider {
    margin: 0 -15px;
    > .employee {
        &:not(:first-child) {
            display: none;
        }
    }
    .slick-track {
        display: flex;
    }
    .slick-list {
        padding-bottom: 15px;
        margin-bottom: 25px;
    }
    .slick-slide {
        height: auto;
        > div {
            height: 100%;
            padding: 0 15px;
        }
    }
    .slick-dots {
        position: static;
    }
}

.employee {
    background: #fff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, .12);
    border-radius: 5px;
    height: 100%;
    &__top {
        position: relative;
        display: block;
        width: 100%;
        max-width: 390px;
    }
    &__img-link {
        display: block;
        width: 100%;
        height: 100%;
    }
    &__img-wrap {
        display: block;
        width: 100%;
        height: 100%;
    }
    &__img {
        display: block;
        width: 100%;
        height: 100%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__body {
        padding: calcFluid(15, 25) calcFluid(10, 40);
    }
    &__name {
        @include font-24;
        font-weight: 700;
        color: #333;
        text-align: center;
        display: block;
        margin-bottom: 5px;
    }
    &__order {
        @include font-16;
        color: #333;
        text-align: center;
    }
}

.employee-loupe {
    position: relative;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: $whiteZoomIcon;
        background-size: 20px 20px;
        background-position: 95% 5%;
        background-repeat: no-repeat;
        opacity: 0;
        transition: .3s;
    }
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $main-color-primary;
        opacity: 0;
        transition: .3s;
    }
    &:hover {
        &:after {
            background-position: 50% 50%;
            background-size: 35px 35px;
            opacity: 1;
        }
        &:before {
            opacity: .5;
        }
    }
    &__img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.employees-slider-arrows {
    position: absolute;
    top: 0;
    left: 10px;
    right: 10px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    pointer-events: none;
    $this:&;
    &__arrow {
        width: 30px;
        height: 60px;
        background: #fff;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .15s;
        pointer-events: all;
        &.next {
            #{ $this }__svg {
                transform: rotate(180deg);
            }
        }
        &:hover {
            background-color: $main-color-primary;
            transition: .3s;
            #{ $this }__svg {
                fill: #fff;
                transition: .3s;
            }
        }
    }
    &__svg {
        width: 8px;
        height: 12px;
        fill: #333;
        transition: .15s;
    }
}


@media(max-width: 1199px) {
    .employees-slider-arrows {
        display: none;
    }
}