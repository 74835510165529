.banner-slider {
    &__item {
        &:not(:first-child) {
            display: none;
        }
    }
}

.banner-slider-wrapper {
    position: relative;
}

.banner-slider-controls {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    pointer-events: none;
    $this:&;
    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__arrow {
        background-color: #fff;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 60px;
        pointer-events: all;
        transition: background-color .3s;
        &.prev {
            margin-left: calcFluid(-35, -60, 1200, 1920);
        }
        &.next {
            margin-right: calcFluid(-35, -60, 1200, 1920);
            #{ $this }__svg {
                transform: rotate(180deg);
            }
        }
        &:focus {
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        }
        &:hover {
            background-color: $main-color-primary;
            #{ $this }__svg {
                fill: #fff;
            }
        }
    }
    &__svg {
        display: block;
        width: 12px;
        height: 15px;
        fill: #333;
        transition: fill .3s;
    }
}

.slick-dots {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: calcFluid(10, 30);
    z-index: 1;
    li {
        width: 8px;
        height: 8px;
        border: 1px solid $color-theme_text;
        border-radius: 50%;
        background-color: transparent;
        transition: background-color .3s;
        margin: 0 4px;
        &.slick-active {
            background-color: $main-color-secondary;
            border: 1px solid transparent;
        }
        * {
            display: none;
        }
    }
}

@media(max-width: 1199px) {
    .section-banner {
        &__wrap {
            padding-left: calcFluid(0, 30, 320, 1199);
        }
    }
    .banner-slider-controls {
        &__arrow {
            &.prev {
                margin-left: calcFluid(0, -15, 320, 1199);
            }
            &.next {
                margin-right: calcFluid(0, -15, 320, 1199);
            }
        }
    }
}

@media(max-width: 767px) {
    .banner-slider-controls {
        &__wrapper {
            display: none;
        }
    }
}

@media(max-width: 400px) {
    .banner-slider-controls {
        align-items: flex-end;
    }
}