.section-FAQ {
    padding: calcFluid(25, 55) 0;
    &__title {
        @include font-32;
        font-weight: 700;
        color: var(--color-theme_text);
        margin-bottom: calcFluid(20, 40);
    }
    &__wrap {
        position: relative;
    }
}

.FAQ {
    $this:&;
    &:not(:last-child) {
        margin-bottom: calcFluid(20, 40);
    }
    &__item {
        &.active,
        &:hover {
            #{ $this }__question {
                box-shadow: 0px 1px 16px rgba(0, 0, 0, .07);
                border-radius: 5px;
                &:after {
                    opacity: 1;
                }
                border-left-color: $main-color-primary;
            }
        }

        &:first-child {
            #{ $this }__question {
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
            }
        }

        &:last-child {
            #{ $this }__question {
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }

        &.active {
            #{ $this }__question {
                border-color: transparent;
            }
            #{ $this }__question-text {
                color:  $main-color-primary;
            }
            .cc-pluse {
                &:after, &:before {
                    transform: rotate(45deg);
                }
            }
        }
        &:not(:last-child) {
            #{ $this }__question {
                border-bottom: none;
            }
        }
    }
    &__question {
        background: #fff;
        border: 1px solid #E4E4E4;
        padding: calcFluid(15, 28) calcFluid(15, 33);
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: .3s;
        position: relative;
        cursor: pointer;
        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: calcFluid(4, 7);
            background-color: $main-color-primary;
            opacity: 0;
            transition: .3s;
        }
    }
    &__question-text {
        @include font-16;
        color: #333;
        transition: .3s
    }
    &__answer {
        padding: calcFluid(15, 25) calcFluid(15, 55) calcFluid(20, 30);
    }
    &__answer-text {
        p {
            @include font-16;
            // color: #828282;
            color: var(--color-theme_text);
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }
}

.cc-pluse {
    position: relative;
    width: 15px;
    height: 15px;
    flex-shrink: 0;
    margin-left: 10px;
    &:after, &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #333;
        margin: auto;
        transition: .3s;
    }
    &:after {
        width: 100%;
        height: 2px;
    }
    &:before {
        width: 2px;
        height: 100%;
    }
}

.didnt-answer {
    background: $main-color-primary;
    border-radius: 5px;
    padding: calcFluid(20, 40) calcFluid(20, 70);
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__title {
        font-weight: 700;
        @include font-24;
        color: var(--text-color);
        width: calc(40% - 30px);
    }
    &__text {
        @include font-16;
        color: var(--text-color);
        width: calc(30% - 30px);
    }
    &__button {
        height: 50px;
        width: calc(30% - 30px);
    }
}



@media(max-width: 767px) {
	.didnt-answer {
		flex-direction: column;
		align-items: center;
        text-align: center;
        &__title {
            width: 100%;
            margin-bottom: 10px;
        }
        &__text {
            width: 100%;
            margin-bottom: 30px;
        }
        &__button {
            max-width: 270px;
            width: 100%;
        }
	}
}