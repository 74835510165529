.section-catalog {
    padding: calcFluid(25, 55) 0;
    &__header {
        margin-bottom: 40px;
        display: flex;
        align-items: center;
    }
    &__title {
        @include font-32;
        font-weight: 700;
        color: var(--color-theme_text);
        margin-right: 15px;
    }
}

.catalog-nav-wrap {
    overflow-x: auto;
    padding-bottom: 30px;
    display: grid;
}

.catalog-nav {
    position: relative;
    display: flex;
    z-index: 0;
    white-space: nowrap;
    &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        background-color: #E4E4E4;
    }
    &__item {
        @include font-20;
        position: relative;
        font-weight: 500;
        color: $color-theme_text;
        padding-bottom: 12px;
        flex-shrink: 0;
        &:not(:last-child) {
            margin-right: calcFluid(40, 60, 992, 1920);
        }
        &:after {
            content: "";
            position: absolute;
            z-index: 1;
            left: 0;
            right: 0;
            bottom: -1px;
            height: 3px;
            background-color: $main-color-primary;
            opacity: 0;
            transition: .3s;
        }
        &.active {
            color: $color-theme_text;
            font-weight: 700;
            &:after {
                opacity: 1;
            }
        }
    }
}

.product-cards {
    padding-top: 10px;
    padding-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    margin: -15px;
}

.product-card {
    background: #fff;
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    padding: calcFluid(15, 25) calcFluid(15, 25) calcFluid(20, 30);
    position: relative;
    width: calc(25% - 30px);
    margin: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    $this:&;
    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .amount {
            width: calc(50% - 3px);
            height: 50px;
        }
        #{ $this }__button {
            width: calc(50% - 3px);
        }
    }
    &__header {
        margin-bottom: 28px;
    }
    &__img {
        display: block;
        width: 100%;
        object-fit: contain;
    }
    &__body {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    &__title {
        @include font-18;
        font-weight: 700;
        color: #333;
        display: block;
        margin-bottom: 12px;
    }
    &__desc {
        @include font-14;
        color: #333;
        display: block;
        margin-bottom: 20px;
    }
    &__button {
        width: 100%;
        height: 50px;
        margin-top: auto;
    }
    &:hover {
        cursor: pointer;
    }
}

.product-card-price {
    display: flex;
    align-items: baseline;
    margin-bottom: 25px;
    &__new-price {
        @include font-22;
        font-weight: 700;
        color: #333;
        margin-right: 15px;
    }
    &__old-price {
        @include font-15;
        font-weight: 700;
        text-decoration-line: line-through;
        color: #C4C4C4;
    }
}

.product-markers {
    position: absolute;
    z-index: 1;
    top: 13px;
    left: 13px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .product-marker {
        border-radius: 3px;
        padding: 2px 13px;
        color: #fff;
        @include font-14;
        &:not(:last-child) {
            margin-bottom: 8px;
        }
        &.a {
            background-color: #1A2B67;
        }
        &.b {
            background-color: #D81717;
        }
        &.c {
            background-color: #EA6726;
        }
    }
}

.show-more-wrap {
    display: flex;
    justify-content: center;
}

.show-more {
    display: flex;
    align-items: center;
    $this:&;
    &__text {
        @include font-18;
        font-weight: 500;
        color: $main-color-primary;
        text-decoration: underline;
        margin-right: 12px;
    }
    &__svg {
        width: 8px;
        height: 12px;
        transform: rotate(-90deg);
        fill: $main-color-primary;
    }
    &:hover {
        #{ $this }__text {
            text-decoration: none;
        }
    }
}

.product-modal {
    .custom-modal__wrapper {
        width: 1100px;
    }
    .custom-modal-header {
        padding: 10px 15px 15px;
    }
    .custom-modal-body {
        padding: 0 calcFluid(15, 40) calcFluid(15, 40);
    }
    &__title {
        @include font-32;
        font-weight: 700;
        color: #333;
        display: block;
        margin-bottom: 35px;
    }
    &__wrap {
        display: flex;
        justify-content: space-between;
    }
    &__left {
        width: calcFluidM(10, 20, 767, 1920, 50);
        padding-bottom: 40px;
    }
    &__right {
        width: calcFluidM(10, 20, 767, 1920, 50);
        min-height: 200px;
    }
}

.product-slider {
    $this:&;
    &--nav {
        margin: 0 -10px;
        .slick-track {
            padding-bottom: 3px;
        }
        .slick-slide {
            padding: 0 10px;
            > div {
                padding: 5px;
                border: 1px solid #E9E9E9;
                border-radius: 5px;
                position: relative;
                transition: .3s;
                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 1;
                    opacity: 0;
                    background-color: $main-color-primary;
                    transition: .3s;
                    visibility: hidden;
                }
            }
            &.slick-current {
                > div {
                    border-color: $main-color-primary;
                    &:before {
                        visibility: visible;
                        opacity: .2;
                    }
                }
            }
        }
        .slick-dots {
            top: calcFluidP(10, 20);
        }
        #{ $this }__item {
            height: calcFluid(50, 80, 767, 1920);
        }
    }
    &--for {
        border: 1px solid #E9E9E9;
        border-radius: 5px;
        margin-bottom: 15px;
        .slick-slide {
            > div {
                padding: 10px;
            }
        }
        #{ $this }__item {
            height: calcFluid(260, 390, 767, 1920);
        }
    }
    &__item {
        width: 100%;
        display: block !important;
        object-fit: contain;
        &:not(:first-child) {
            display: none;
        }
    }
}

.wrap-product-slider-nav {
    position: relative;
}

.product-slider-arrows {
    position: absolute;
    top: 0;
    left: 5px;
    right: 5px;
    bottom: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    $this: &;
    &__arrow {
        background-color: #fff;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 42px;
        pointer-events: all;
        transition: background-color .3s;
        &.next {
            #{ $this }__svg {
                transform: rotate(180deg);
            }
        }
        &:hover {
            background-color: $main-color-primary;
            #{ $this }__svg {
                fill: #fff;
            }
        }
    }
    &__svg {
        display: block;
        width: 7px;
        height: 12px;
        fill: #333;
        transition: fill .3s;
    }
}

.simplebar-container {
    height: 100%;
}

.product-modal-content {
    padding-right: 20px;
    position: relative;
    $this:&;
    &__header {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
    }
    &__top {
        display: flex;
        padding: 15px 0 20px;
        border-top: 1px solid #F1F1F1;
        border-bottom: 1px solid #F1F1F1;
    }
    &__body {
        padding-top: 15px;
    }
    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }
    &__bottom {
        padding-top: 40px;
        padding-bottom: 20px;
    }
    &.loading {
        .loading-animate-wrap {
            display: flex;
        }
    }
}

.product-count {
    @include font-12;
    color: #9FA3A7;
    display: block;
    margin-bottom: 12px;
}

.product-select-options {
    &:not(:last-child) {
        margin-right: 50px;
    }
    &__title {
        @include font-12;
        color: #9FA3A7;
        display: block;
        margin-bottom: 10px;
    }
}

.color-picker {
    display: flex;
    align-items: center;
    $this:&;
    &__item {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:not(:last-child) {
            margin-right: 10px;
        }
    }
    &__input {
        display: none;
        &:checked {
            + #{ $this }__svg {
                opacity: 1;
            }
        }
    }
    &__svg {
        opacity: 0;
        transition: .3s;
        width: 15px;
        height: 11px;
        fill: #fff;
    }
}

.sizes-picker {
    display: flex;
    align-items: center;
    $this:&;
    &__item {
        cursor: pointer;
        &:not(:last-child) {
            margin-right: 10px;
        }
    }
    &__input {
        display: none;
        &:checked {
            + #{ $this }__text {
                opacity: 1;
                border-color: #E9E9E9;
                color: #333;
            }
        }
    }
    &__text {
        @include font-12;
        color: #9FA3A7;
        font-weight: 700;
        border: 2px solid transparent;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s;
        text-transform: uppercase;
    }
}

.simplebar-track {
    &.simplebar-vertical {
        background-color: #eee;
        width: 1px;
        overflow: visible;
    }
}

.simplebar-scrollbar {
    &:before {
        background-color: $main-color-primary;
        left: -1px;
        right: -1px;
        opacity: 1;
    }
}

.amount-wrap {
    display: flex;
    align-items: center;
}

.amount-title {
    @include font-12;
    color: #9FA3A7;
    margin-right: calcFluid(10, 20);
}

.amount {
    display: flex;
    overflow: hidden;
    max-width: 100px;
    max-height: 42px;
    background: #fff;
    border: 1px solid #D5D5D5;
    border-radius: 5px;
    &__button {
        padding: 5px;
        width: 30%;
        flex: 0 1 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        @include font-24;
        font-weight: 700;
        color: #B6B6B6;
        &:hover, &:focus {
            color: #333;
        }
    }
    &__input {
        flex: 1 0 auto;
        border: none;
        text-align: center;
        font-size: 18px;
    }
}

.modal-product-price {
    display: flex;
    align-items: baseline;
    &__new {
        @include font-22;
        font-weight: 700;
        color: #333;
        margin-right: 15px;
    }
    &__old {
        @include font-15;
        font-weight: 700;
        text-decoration-line: line-through;
        color: #C4C4C4;
    }
}

.modal-product-total {
    color: #333;
    @include font-15;
    &__cost {
        @include font-22;
        font-weight: 700;
    }
}

.modal-product-form {
    background-color: #FFFBF9;
    border-radius: 5px;
    padding: calcFluid(15, 28) calcFluid(15, 40) calcFluid(10, 26);
    &__wrap {
        display: flex;
        justify-content: space-between;
        margin-bottom: calcFluid(7, 15);
    }
    &__field {
        width: calc(50% - 7px);
        .field__input {
            background: transparent;
            border: 1px solid #DCDCDC;
        }
        .field__text {
            color: #B4B1B0;
        }
    }
    &__submit {
        width: calc(50% - 7px);
    }
    .personal {
        @include font-14;
        color: #B4B1B0;
        > a {
            color: #B4B1B0;
        }
    }
}

.modal-product-info {
    &__tabs {
        border-bottom: 1px solid #E4E4E4;
        display: flex;
    }
    &__tab {
        @include font-16;
        font-weight: 500;
        color: #D6D6D6;
        padding-bottom: 15px;
        position: relative;
        transition: .3s;
        display: block;
        &:not(:last-child) {
            margin-right: calcFluid(15, 65);
        }
        &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            z-index: 1;
            bottom: -2px;
            height: 3px;
            background-color: $main-color-primary;
            opacity: 0;
            transition: .3s;
        }
        &.active {
            color: #333;
            &:after {
                opacity: 1;
            }
        }
    }
    &__content {
        padding-top: calcFluid(10, 20);
        display: none;
        &.active {
            display: block;
        }
    }
    &__text {
        p {
            @include font-14;
            color: #333333;
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }
}

.slider-previews {
    width: 100%;
    > .slider-previews__item {
        &:not(:first-child) {
            display: none;
        }
    }
    &__item {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .slick-slide {
        height: 100%;
        > div {
            height: 100%;
        }
    }
    .slick-track {
        height: 100%;
    }
    .slick-list {
        height: 100%;
    }
}

.product-card-drop {
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100% - 1px);
    background-color: #fff;
    z-index: 2;
    box-shadow: 0px 19px 18px rgba(0, 0, 0, 0.08);
    padding: 10px 15px 30px 25px;
    opacity: 0;
    &.init {
        opacity: 1;
    }
    &__row {
        display: flex;
        margin-bottom: 20px;
    }
    &__count {
        @include font-12;
        color: #9FA3A7;
        margin-bottom: 18px;
    }
}

.product-card-drop-simplebar {
    max-height: 400px;
    padding-right: 10px;
}

.product-card-drop-options {
    &__title {
        @include font-12;
        color: #9FA3A7;
        margin-bottom: 8px;
    }
    &:not(:last-child) {
        margin-right: 30px;
    }
    &__item {
        display: none;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        user-select: none;
        cursor: pointer;
        &.color {
            border: none;
            color: transparent;
        }
        &.size {
            border: 2px solid #E9E9E9;
            color: #333;
            text-transform: uppercase;
        }
        &.checked {
            display: flex;
        }
    }
}

.product-card-text-info {
    &__wrap {
        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }
    &__title {
        @include font-14;
        font-weight: 500;
        color: #353535;
        margin-bottom: 8px;
    }
    &__text {
        p {
            @include font-12;
            color: #353535;
            &:not(:last-child) {
                margin-bottom: 7px;
            }
        }
    }
}


@media(max-width: 991px) {
    .product-card {
        width: calc(33.3334% - 30px);
    }
}

@media(max-width: 767px) {
    .product-card {
        width: calc(50% - 30px);
    }
    .product-modal {
        &__wrap {
            flex-direction: column;
        }
        &__left {
            width: 100%;
        }
        &__right {
            width: 100%;
        }
    }
    .product-card-drop {
        display: block;
        position: static;
        box-shadow: none;
        padding: 15px 0 0;
        opacity: 1;
    }
    .product-slider {
        $this:&;
        &__for {
            #{ $this }__item {
                height: calcFluid(200, 300, 320, 767);
            }
        }
        &__nav {
            #{ $this }__item {
                height: calcFluid(35, 120, 320, 767);
            }
        }
    }
}

@media(max-width: 575px) {
    .product-card {
        width: calc(100% - 30px);
    }
    .section-catalog__header {
        flex-direction: column-reverse;
        align-items: flex-start;
    }
}

@media(max-width: 450px) {
    .product-modal-content {
        padding-right: 0px;
        &__header {
            flex-direction: column;
            align-items: flex-start;
        }
        &__top {
            flex-direction: column;
        }
        &__row {
            flex-direction: column;
            align-items: flex-start;
        }
    }
    .product-select-options {
        &:not(:last-child) {
            margin-right: 0;
            margin-bottom: 20px;
        }
    }
    .modal-product-price {
        margin-bottom: 10px;
    }
    .product-slider-arrows {
        display: none;
    }
    .amount-wrap {
        margin-bottom: 10px;
    }
    .modal-product-form {
        margin: 0 -10px;
        &__wrap {
            flex-direction: column;
        }
        &__field {
            width: 100%;
            margin-bottom: 10px;
        }
        &__submit {
            width: 100%;
            height: 50px;
        }
    }
}