// Fonts


@function calcFluid($f-min, $f-max, $w-min: 320, $w-max: 1920) {
	$k: ($f-max - $f-min)/($w-max - $w-min);
	$b: $f-min - $k * $w-min;
  
	$b: $b * 1px;
  
	@return calc( #{$k} * 100vw + #{$b} );
}

@function calcFluidP($f-min, $f-max, $v-min: 320, $v-max: 1920, $percent: 100) {
	$k: ($f-max - $f-min)/($v-max - $v-min);
	$b: $f-min - $k * $v-min;
  
	$b: $b * 1px;

	$p: $percent * 1%;
  
	@return calc( #{$p} + (#{$k} * 100vw + #{$b}) );
}

@function calcFluidM($f-min, $f-max, $v-min: 320, $v-max: 1920, $percent: 100) {
	$k: ($f-max - $f-min)/($v-max - $v-min);
	$b: $f-min - $k * $v-min;
  
	$b: $b * 1px;

	$p: $percent * 1%;
  
	@return calc( #{$p} - (#{$k} * 100vw + #{$b}) );
}






// General fonts
@mixin font-h1 {
    color: var(--color-theme_text);
    font-weight: 700;
    @include font-32;
    display: block;
}

@mixin font-h2 {
	color: var(--color-theme_text);
	@include font-24;
	font-weight: 700;
}

@mixin font-h3 {
	color: var(--color-theme_text);
	@include font-20;
	font-weight: 700;
}

@mixin font-p {
	color: var(--color-theme_text);
	@include font-16;
	font-weight: 400;
}

@mixin font-45 {
	font-size: calcFluid(24, 45);
	line-height: calcFluid(28, 51);
}

@mixin font-36  {
	font-size: calcFluid(21, 36);
	line-height: calcFluid(26, 42);
}

@mixin font-32  {
	font-size: calcFluid(20, 32);
	line-height: calcFluid(26, 38);
}

@mixin font-26  {
	font-size: calcFluid(18, 26);
	line-height: calcFluid(22, 32);
}

@mixin font-24  {
	font-size: calcFluid(17, 24);
	line-height: calcFluid(22, 30);
}

@mixin font-22  {
	font-size: calcFluid(16, 22);
	line-height: calcFluid(22, 30);
}

@mixin font-20 {
	font-size: calcFluid(15, 20);
	line-height: calcFluid(20, 26);
}

@mixin font-18 {
	font-size: calcFluid(14, 18);
	line-height: calcFluid(18, 24);
}

@mixin font-16 {
	font-size: calcFluid(13, 16);
	line-height: calcFluid(18, 22);
}

@mixin font-15 {
	font-size: calcFluid(13, 15);
	line-height: calcFluid(18, 22);
}

@mixin font-14 {
	font-size: calcFluid(12, 14);
	line-height: calcFluid(16, 16);
}

@mixin font-13 {
	font-size: calcFluid(11, 13);
	line-height: calcFluid(12, 16);
}

@mixin font-12 {
	font-size: calcFluid(10, 12);
	line-height: calcFluid(12, 16);
}


// Color theme
@mixin dark-button_mod-1{
	color: #333;
	background-color: var(--primary) !important;
	transition: border-color ease $transition;
	border-color: transparent;
	&:hover{
		border-color:#898989;
	}
	span{
		color: #fff !important;
	}
}

@mixin dark-button_mod-2{
	border: 1px solid #fff !important;
	background-color: transparent !important;
	span{
		color: #fff !important;
	}
	&:hover {
		background-color: var(--primary) !important;
	}
}

@mixin dark-button_mod-3{
	border: 1px solid #fff !important;
	background-color: #fff !important;
	span{
		color: var(--primary) !important;
	}
	&:hover {
		border-color:#898989;
		background-color: var(--primary) !important;
		span {
			color: $color-theme_text !important;
		}
	}
}


@mixin light-button_mod-1{
	border: 1px solid #333;
	background-color: #fff;	
	span{
		color: #333;
	}
	&:hover {
		background-color: var(--primary);		
		span{
			color: #fff;
		}
	}
}

@mixin light-button_mod-2{
	border: 1px solid transparent;
	background-color: var(--primary);	
	span{
		color: #fff;
	}
	&:hover{
		border-color: #333;
	}
}