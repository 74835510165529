// Общие для всех типов
.section-tariffs {
    padding: calcFluid(25, 55) 0;
    &__wrap {
        position: relative;
    }
    &__title {
        font-weight: 700;
        @include font-32;
        color: var(--color-theme_text);
        display: block;
        margin-bottom: calcFluid(20, 40);
    }
    div.tariff--hidden {
        display: none;
    }
    .tariffs-show-btn {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: max-content;
        border: none;
        cursor: pointer;
        background: transparent;
        margin: calcFluid(20, 40) auto 0;
        &__text {
            @include font-18;
            color: #333;
            text-decoration: underline;
        }
        &__icon {
            width: 7px;
            height: 12px;
            margin-left: 10px;
            transform: rotate(270deg);
            transition: .7s;
        }
        &:hover {
            .tariffs-show-btn__text {
                text-decoration: none;
            }
        }
        &.shown {
            .tariffs-show-btn__icon {
                transform: rotate3d(0, 1, 0, 180deg) rotate(90deg);
            }
        }
    }
}


// Type one. Три и четыре колонки
.section-tariffs--one {
    .tariffs {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        row-gap: calcFluid(15, 30);
        column-gap: calcFluid(15, 30);
        &--count-1{
    
            width: fit-content;
            margin: auto;
    
            grid-template-columns: repeat(1, 270px);
        }
        &--count-2{
    
            width: fit-content;
            margin: auto;
    
            grid-template-columns: repeat(2, 270px);
    
        }
        &--count-3{
    
            width: fit-content;
            margin: auto;
    
            grid-template-columns: repeat(3, 270px);
    
        }
    }
    .tariff {
        box-shadow: 0px 4px 15px rgba(0,0,0,.12);
        border-radius: 5px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        border: 1px solid transparent;
        transition: .3s;
        &__header {
    
            // background-color: $main-color-secondary;
    
            background-color: #fff;
            border-bottom: 1px dashed #D6D6D6;
    
            padding: calcFluid(13, 23);
            text-align: center;
            transition: .3s;
    
        }
        &__price {
            font-weight: 700;
            @include font-20;
            color: #333;
            > span {
                @include font-26;
            }
        }
        &__body {
            padding: calcFluid(15, 30);
            background-color: #fff;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            z-index: 0;
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                // background-color: $main-color-primary;
                opacity: 0;
                z-index: -1;
                transition: .3s;
            }
        }
        &__cat {
            @include font-16;
            color: #333;
            display: block;
            margin-bottom: 5px;
        }
        &__name {
            font-weight: 700;
            @include font-24;
            color: #333;
            display: block;
            margin-bottom: 23px;
        }
        &__wrap {
            margin-bottom: calcFluid(20, 40);
        }
        &__button {
            height: 50px;
            @include light-button_mod-1;
        }
        &:hover, &.active {
            border-color: $main-color-primary;
            box-shadow: none;
            .tariff__header {
                background-color: $main-color-primary;
                border-bottom-color: transparent;
            }
            .tariff__price{
                color: #fff;
            }
            .tariff__body {
                &:after {
                    opacity: 0.2;
                }
            }
            .tariff__button {
                @include light-button_mod-2;
            }
        }
    }
    .tariff-list {
        &__item {
            @include font-14;
            color: #333;
            padding-left: 18px;
            position: relative;
            &:not(:last-child) {
                margin-bottom: 10px;
            }
            &:before {
                content: "";
                position: absolute;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: #333;
                left: 0;
                top: 6px;
            }
            &.important {
                color: $main-color-primary;
                &:before {
                    background-color: $main-color-primary;
                }
            }
        }
    }
}
@media (min-width: 992px) {
    .section-tariffs--one .tariffs.col-3 {
        grid-template-columns: repeat(3, 1fr);
    }
};
@media(max-width: 991px) {
    .section-tariffs--one .tariffs {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media(max-width: 480px) {
    .section-tariffs--one .tariffs {
        grid-template-columns: repeat(1, 1fr);
    }
}


// Type two. В одну строку
.section-tariffs--two {
    .tariffs {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: calcFluid(15, 30);
        column-gap: calcFluid(15, 30);
    }
    
    .tariff {
        border-radius: 5px;
        overflow: hidden;
        display: flex;
        border: 1px solid #d6d6d6;
        border-left: calcFluid(5, 16) solid #333;
        transition: .3s;
        &__col1 {
            width: 23%;
            padding: calcFluid(15, 38) calcFluid(15, 35);
            flex-shrink: 0;
        }
        &__col2 {
            min-width: 0;
            flex-grow: 1;
        }
        &__col3 {
            width: 27%;
            padding: calcFluid(15, 40);
            flex-shrink: 0;
        }
        &__cat {
            @include font-16;
            color: #333;
            display: block;
            margin-bottom: 5px;
        }
        &__name {
            font-weight: 700;
            @include font-24;
            color: #333;
            display: block;
        }
        &__price {
            font-weight: 700;
            @include font-20;
            color: #333;
            margin-bottom: calcFluid(7, 15);
            > span {
                @include font-26;
            }
        }
        &__button {
            height: 50px;
            @include light-button_mod-1;
            &:hover {
                @include light-button_mod-2;
            }
        }
        &:hover, &.active {
            border-left-color: $main-color-primary;
            .tariff__header {
                background-color: $main-color-primary;
                border-bottom-color: transparent;
            }
        }
    }
    
    .tariff-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;
        align-content: center;
        padding: 0 calcFluid(15, 45);
        height: 100%;
        border-left: 1px dashed #D6D6D6;
        border-right: 1px dashed #D6D6D6;
        &__item {
            font-size: 12px;
            line-height: 16px;
            color: #333;
            padding-left: 18px;
            position: relative;
            &:not(:last-child) {
                margin-bottom: 10px;
            }
            &:before {
                content: "";
                position: absolute;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: #333;
                left: 0;
                top: 6px;
            }
            &.important {
                color: $main-color-primary;
                &:before {
                    background-color: $main-color-primary;
                }
            }
        }
    }
}
@media(max-width: 991px) {
    .section-tariffs--two {
        .tariffs {
            grid-template-columns: 1fr 1fr;
        }
        .tariff {
            flex-direction: column;
            &__col1 {
                width: 100%;
            }
            &__col3 {
                width: 100%;
            }
        }
    }
}
@media(max-width: 767px) {
    .section-tariffs--two {
        .tariff-list {
            grid-template-columns: 1fr;
        }
    }
}
@media(max-width: 576px) {
    .section-tariffs--two {
        .tariffs {
            grid-template-columns: 1fr;
        }
    }
}



// Type three. В две колонки
.section-tariffs--three {
    .tariffs {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: calcFluid(15, 30);
        column-gap: calcFluid(15, 30);
    }
    .tariff {
        border-radius: 5px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        border: 1px solid #d6d6d6;
        transition: .3s;
        &__col1 {
            padding: calcFluid(8, 12) calcFluid(15, 30);
            background-color: #333333;
        }
        &__col2 {
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            grid-column-gap: 10px;
            padding: calcFluid(10, 20) calcFluid(15, 30) calcFluid(15, 30);
        }
        &__col3 {
            border-top: 1px dashed #D6D6D6;
            padding: calcFluid(10, 20) calcFluid(15, 30) calcFluid(15, 30);
        }
        &__price {
            font-weight: 700;
            @include font-20;
            color: #fff;
            > span {
                @include font-26;
            }
        }
        &__cat {
            order: 1;
            @include font-16;
            color: #333;
            display: block;
            margin-bottom: 5px;
        }
        &__name {
            order: 3;
            font-weight: 700;
            @include font-24;
            color: #333;
            display: block;
        }
        &__button {
            width: 85%;
            justify-self: end;
            grid-row-start: span 2;
            order: 2;
            height: 50px;
            @include light-button_mod-1;
            
        }
        &:hover, &.active {
            border-color: transparent;
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.12);
            .tariff__col1 {
                background-color: $main-color-primary;
            }
            .tariff__price {
                // color: #333;
            }
            .tariff__header {
                background-color: $main-color-primary;
                border-bottom-color: transparent;
            }
            .tariff__button {
                @include light-button_mod-2;
            }
        }
    }
    .tariff-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;
        align-content: center;
        height: 100%;
        &__item {
            font-size: 12px;
            line-height: 16px;
            color: #333;
            padding-left: 18px;
            position: relative;
            &:not(:last-child) {
                margin-bottom: 10px;
            }
            &:before {
                content: "";
                position: absolute;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: #333;
                left: 0;
                top: 6px;
            }
            &.important {
                color: $main-color-primary;
                &:before {
                    background-color: $main-color-primary;
                }
            }
        }
    }
}
@media(max-width: 991px) {
    .section-tariffs--three {
    }
}
@media(max-width: 767px) {
    .section-tariffs--three {
        .tariff {
            &__col2 {
                grid-template-columns: 1fr;
                grid-row-gap: 10px;
            }

            &__cat {
                order: 1;
            }
            &__name {
                order: 2;
            }
            &__button {
                width: 100%;
                grid-row-start: auto;
                justify-self: start;
                order: 3;
            }
        }
        .tariff-list {
            grid-template-columns: 1fr;
        }
    }
}
@media(max-width: 480px) {
    .section-tariffs--three {
        .tariffs {
            grid-template-columns: 1fr;
        }
    }
}



// Type four. Слайдер
.section-tariffs--four {
    .slick-track {
        display: flex;
    }
    .slick-list {
        padding: 15px 0;
        margin: -15px -15px 15px;
    }
    .slick-slide {
        height: auto;
        > div {
            height: 100%;
            padding: 0 15px;
        }
    }
    .slick-dots {
        position: static;
    }
    .tariffs {
    }
    .tariff {
        box-shadow: 0px 4px 15px rgba(0,0,0,.12);
        border-radius: 5px;
        overflow: hidden;
        display: flex!important;
        height: 100%;
        flex-direction: column;
        border: 1px solid transparent;
        transition: .3s;
        &__header {
            // background-color: $main-color-secondary;
            background-color: #fff;
            border-bottom: 1px dashed #D6D6D6;
            padding: calcFluid(13, 23);
            text-align: center;
            transition: .3s;
        }
        &__price {
            font-weight: 700;
            @include font-20;
            color: #333;
            > span {
                @include font-26;
            }
        }
        &__body {
            padding: calcFluid(15, 30);
            background-color: #fff;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            z-index: 0;
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                // background-color: $main-color-primary;
                opacity: 0;
                z-index: -1;
                transition: .3s;
            }
        }
        &__cat {
            @include font-16;
            color: #333;
            display: block;
            margin-bottom: 5px;
        }
        &__name {
            font-weight: 700;
            @include font-24;
            color: #333;
            display: block;
            margin-bottom: 23px;
        }
        &__wrap {
            margin-bottom: calcFluid(20, 40);
        }
        &__button {
            height: 50px;
            @include light-button_mod-1;
        }
        &:hover, &.active {
            border-color: $main-color-primary;
            box-shadow: none;
            .tariff__header {
                background-color: $main-color-primary;
                border-bottom-color: transparent;
            }
            .tariff__price{
                color: #fff;
            }
            .tariff__body {
                &:after {
                    opacity: 0.2;
                }
            }
            .tariff__button {
                @include light-button_mod-2;
            }
        }
    }
    .tariff-list {
        &__item {
            @include font-14;
            color: #333;
            padding-left: 18px;
            position: relative;
            &:not(:last-child) {
                margin-bottom: 10px;
            }
            &:before {
                content: "";
                position: absolute;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: #333;
                left: 0;
                top: 6px;
            }
            &.important {
                color: $main-color-primary;
                &:before {
                    background-color: $main-color-primary;
                }
            }
        }
    }
}
.tariffs-slider-arrows {
    position: absolute;
    top: 0;
    left: 10px;
    right: 10px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    pointer-events: none;
    $this:&;
    &__arrow {
        width: 30px;
        height: 60px;
        background: #fff;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .15s;
        pointer-events: all;
        &.next {
            margin-right: -70px;
            #{ $this }__svg {
                transform: rotate(180deg);
            }
        }
        &.prev {
            margin-left: -70px;
        }
        &:hover {
            background-color: $main-color-primary;
            transition: .3s;
            #{ $this }__svg {
                fill: #fff;
                transition: .3s;
            }
        }
    }
    &__svg {
        width: 8px;
        height: 12px;
        fill: #333;
        transition: .15s;
    }
}
@media(max-width: 1199px) {
    .tariffs-slider-arrows {
        display: none;
    }
}
@media (min-width: 992px) {
    .section-tariffs--four{
    }
};
@media(max-width: 991px) {
    .section-tariffs--four .tariffs {
    }
}
@media(max-width: 480px) {
    .section-tariffs--four .tariffs {
    }
}