.section-action {
    position: relative;
    background-color: #FAFAFA;

    &::before {
        content: '';
        position: absolute;
        top: 22px;
        left: 0;
        z-index: -1;
        width: 100%;
        height: calc(100% - 44px);
    }

    &__img {
        display: block;
        width: calcFluid(250, 300, 992, 1920);
    }
    &__wrap {
        display: flex;
        justify-content: space-between;
    }
    &__right {
        display: flex;
        align-items: center;
        padding: calcFluid(15, 35) 0 40px;
    }
    &__left {
        margin-right: calcFluid(20, 60, 992, 1920);
        max-width: calcFluid(370, 430, 992, 1920);
        width: 100%;
    }
    &__title {
        font-weight: 700;
        @include font-36;
        color: #333;
        display: block;
        margin-bottom: calcFluid(12, 17);
    }
    &__text {
        @include font-18;
        color: #333;
    }
    &__button {
        width: 270px;
        height: 50px;
        background-color: $main-color-primary;
    }

    &__button-text {
        color: white;
    }
}


@media(max-width: 991px) {
    .section-action {
        &__wrap {
            flex-direction: column;
        }
        &__img {
            margin-left: auto;
            height: auto;
            width: calcFluid(93, 250, 320, 991);
        }
    }
}
@media(max-width: 767px) {
    .section-action {
        &__right {
            flex-direction: column;
            align-items: flex-start;
        }
        &__left {
            margin-right: 0;
            margin-bottom: 30px;
        }
        &__text {
            br {
                display: none;
            }
        }
    }
}