.section-contacts {
    padding: calcFluid(25, 55) 0;
    overflow: hidden;
    $this:&;
    &__title {
        @include font-32;
        font-weight: 700;
        color: var(--color-theme_text);
        display: block;
        margin-bottom: calcFluid(20, 30);
    }
    &__text {
        @include font-16;
        color: var(--color-theme_text);
        display: block;
        margin-bottom: calcFluid(15, 25);
    }
    &--one {
        position: relative;
        z-index: 0;
        padding-top: calcFluid(40, 95);
        padding-bottom: calcFluid(0, 145);
        .container {
            pointer-events: none;
        }
    }
    &--three {
        #{ $this }__right {
            position: relative;
        }
        .city {
            padding-bottom: calcFluid(5, 15);
            margin-bottom: calcFluid(15, 20);
            position: relative;
            &:after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 1px;
                background-color: $main-color-secondary;
                opacity: 0.2;
            }
        }
        .map {
            min-height: 370px;
        }
    }
    &--four {
        .form {
            padding: 0;
        }
    }
    &__wrap {
        display: flex;
        &:not(:last-child) {
            margin-bottom: calcFluid(30, 50);
        }
    }
    &__left {
        width: 430px;
        flex-shrink: 0;
        padding-right: 60px;
    }
    &__right {
        width: calc((100% - 430px) + ((100vw - 100%) / 2));
        flex-shrink: 0;
    }
    &__button {
        width: 100%;
        max-width: 280px;
        height: 50px;
    }
}

.section-contacts-wrap {
    position: relative;
}

.map-container-full-section {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.map {
    width: 100%;
    height: 100%;
    min-height: 500px;
}

.block-over-map {
    width: 470px;
    background-color: #fff;
    padding: calcFluid(25, 50) calcFluid(20, 60);
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    pointer-events: all;
}

.contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &:not(:last-child) {
        margin-bottom: calcFluid(15, 30);
    }
}

.contact {
    display: flex;
    // align-items: center;
    $this:&;
    &:not(:last-child) {
        margin-bottom: calcFluid(9, 18);
    }
    &__svg {
        display: block;
        width: 15px;
        height: 15px;
        margin-right: 15px;
        margin-top: 2px;
        fill: $main-color-secondary;
    }
    &__text {
        @include font-16;
        color: var(--color-theme_text);
        transition: .3s;
    }
    &.link {
        &:hover {
            #{ $this }__text {
                color: $main-color-primary;
            }
        }
        &.contact--sans-hover {
            &:hover {
                #{ $this }__text {
                    color: $color-theme_text;
                }
            }
        }
    }
}

.collapse-wrap {
    margin-bottom: 30px;
}

.collapse {
    border-bottom: 1px solid #eee;
    &:first-child {
        border-top: 1px solid #eee;
    }
    &__header {
        padding: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
    }
    &__body {
        padding: 5px 0 30px;
        display: none;
    }
    &.active {
        .plus {
            opacity: .6;
            &:before {
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(45deg);
            }
        }
    }
    
    .plus {
        position: relative;
        width: 18px;
        max-width: 18px;
        height: 18px;
        max-height: 18px;
        transition: .3s;
        flex-shrink: 0;
        &:after, &:before {
            content: "";
            position: absolute;
            background-color: $main-color-primary;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            transition: .3s;
            border-radius: 5px;
        }
        &:before {
            width: 100%;
            height: 4px;
        }
        &:after {
            width: 4px;
            height: 100%;
        }
    }
}

.city {
    @include font-20;
    font-weight: 500;
    color: var(--color-theme_text);
}

.social-group {
    display: flex;
    &:not(:last-child) {
        margin-bottom: calcFluid(15, 30);
    }
    &__item {
        background-color: $main-color-primary;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        transition: .3s;
        padding: 10px;
        &:hover {
            background-color: $main-color-secondary;
        }
        &:not(:last-child) {
            margin-right: 10px;
        }
    }
    &__svg {
        width: 100%;
        height: 100%;
        display: block;
        fill: #fff;
    }
}

.photo-location {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: calcFluid(110, 260);
    height: calcFluid(80, 180);
    display: block;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: $whiteZoomIcon;
        background-size: 20px 20px;
        background-position: 90% 15%;
        background-repeat: no-repeat;
        transition: .3s;
    }
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $main-color-primary;
        opacity: 0;
        transition: .3s;
    }
    &__img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &:hover {
        &:after {
            background-position: 50% 50%;
            background-size: 35px 35px;
        }
        &:before {
            opacity: .5;
        }
    }
}

.contact-type-four {
    display: flex;
    margin: 0 calcFluid(-15, -60, 767, 1920);
    &__left {
        width: 470px;
        max-width: calcFluidM(30, 120, 767, 1920, 50);
        margin: 0 calcFluid(15, 60, 767, 1920);
    }
    &__right {
        width: 370px;
        max-width: calcFluidM(30, 120, 767, 1920, 50);
        margin: 0 calcFluid(15, 60, 767, 1920);
    }
}



@media(max-width: 991px) {
    .section-contacts {
        &__left {
            width: 300px;
            padding-right: 30px;
        }
        &__right {
            width: calc((100% - 300px) + ((100vw - 100%) / 2));
        }
    }
}


@media(max-width: 767px) {
    .map-container-full-section {
        position: static;
        height: 300px;
        width: 100%;
    }
    .block-over-map {
        width: 100%;
    }
    .section-contacts {
        &__wrap {
            flex-direction: column;
        }
        &__left {
            width: 100%;
            padding: 0;
            margin-bottom: 30px;
        }
        &__right {
            width: 100%;
            height: 240px;
        }
        &--three {
            .map {
                min-height: 240px;
            }
        }
    }
    .contact-type-four {
        margin: 0;
        flex-direction: column;
        &__left {
            width: 100%;
            max-width: 100%;
            margin: 0 0 30px;
        }
        &__right {
            width: 100%;
            max-width: 100%;
            margin: 0;
        }
    }
}