.section-schema {
    padding: calcFluid(25, 55) 0;
    overflow: hidden;
    &__header {
        margin-bottom: calcFluid(20, 50);
        display: flex;
        align-items: center;
    }
    &__title {
        @include font-32;
        font-weight: 700;
        color: var(--color-theme_text);
        margin-right: 15px;
    }
}

.schema-line {
    width: 2px;
    margin: 0 calcFluid(40, 70, 992, 1920);
    flex-shrink: 0;
    background-color: #fff;
    position: relative;
    z-index: 0;
    overflow: hidden;
    &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: var(--height);
        background-color: $main-color-secondary;
    }
    &__filling {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-color: $main-color-secondary;
        height: 0;
        z-index: -2;
    }
}

.schema {
    &__body {
        display: flex;
        justify-content: center;
    }
    &__part {
        position: relative;
        z-index: 1;
        flex-grow: 1;
        max-width: calcFluid(350, 480, 992, 1920);
        height: 100%;
        &--left {
            .schema-step {
                &__ball {
                    left: calcFluidP(26, 51, 992, 1920);
                }
                &__wrap {
                    &:after {
                        left: calc(100% - 1px);
                    }
                    &:before {
                        left: 100%;
                    }
                }
            }
        }
        &--right {
			padding-top: calcFluid(50, 120);
            .schema-step {
                &__ball {
                    right: calcFluidP(26, 51, 992, 1920);
                }
                &__wrap {
                    &:after {
                        right: calc(100% - 1px);
                        transform: rotate(180deg);
                    }
                    &:before {
                        right: 100%;
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
}

.schema-step {
    margin-bottom: calcFluid(35, 90);
    position: relative;
    &__ball {
        width: calcFluid(30, 40, 992, 1920);
        height: calcFluid(30, 40, 992, 1920);
        top: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $main-color-secondary;
        background-color: var(--color-theme);
        border-radius: 50%;
        position: absolute;
        transition: .3s;
        z-index: 1;
        @include font-18;
        color: $color-theme_text;
		&.active {
            background-color: $main-color-secondary;
            color: #fff;
		}
    }
    &__wrap {
        background: #fff;
        border: 1px solid #DFDFDF;
        border-radius: 5px;
        padding: calcFluid(19, 40) calcFluid(10, 50);
        opacity: 0;
        position: relative;
        &:after, &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            width: calcFluid(25, 36, 992, 1920);
            height: calcFluid(40, 52, 992, 1920);
            margin: auto;
            border-left: calcFluid(25, 36, 992, 1920) solid #fff;
            border-top: calcFluid(20, 26, 992, 1920) solid transparent;
            border-bottom: calcFluid(20, 26, 992, 1920) solid transparent;
            box-sizing: border-box;
        }
        &:before{
            border-left-color: #DFDFDF;
        }
    }
    &__title {
        font-weight: 700;
        font-size: calcFluid(20, 24);
        font-weight: calcFluid(25, 30);
        color: #333;
        display: block;
        margin-bottom: 10px;
    }
    &__text {
        font-size: calcFluid(13, 18);
        font-weight: calcFluid(18, 24);
        color: #333;
        a {
            color: $color-theme_text;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
}

.schema-last-step {
    position: relative;
    z-index: 0;
    background-color: var(--color-theme);
    $this:&;
    &__ball {
        width: calcFluid(30, 40, 992, 1920);
        height: calcFluid(30, 40, 992, 1920);
        margin: 0 auto 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $main-color-primary;
        background-color: #fff;
        border-radius: 50%;
        transition: .3s;
        z-index: 1;
        @include font-18;
        color: $main-color-primary;
		&.active {
            background-color: $main-color-primary;
            color: #fff;
		}
    }
    &__wrap {
        border: 1px solid #D6D6D6;
        background-color: #fff;
        border-radius: 5px;
        padding: calcFluid(15, 35) calcFluid(15, 40);
        max-width: 320px;
        margin: 0 auto;
        position: relative;
        z-index: 0;
        opacity: 0;
        text-align: center;
        &:after, &:before {
            content: none;
        }
        // &:after, &:before {
        //     content: '';
        //     position: absolute;
        //     left: 0;
        //     right: 0;
        //     width: calcFluid(40, 52, 992, 1920);
        //     height: calcFluid(25, 36, 992, 1920);
        //     margin: auto;
        //     border-bottom: calcFluid(25, 36, 992, 1920) solid #fff;
        //     border-left: calcFluid(20, 26, 992, 1920) solid transparent;
        //     border-right: calcFluid(20, 26, 992, 1920) solid transparent;
        //     box-sizing: border-box;
        // }
        // &:after {
        //     bottom: calc(100% - 1px);
        // }
        // &:before{
        //     border-bottom-color: #D6D6D6;
        //     bottom: 100%;
        // }
    }
    &__title {
        font-weight: 700;
        @include font-24;
        color: #333;
        display: block;
        margin-bottom: 10px;
    }
    &__text {
        @include font-18;
        color: #333;
        display: block;
        margin-bottom: 25px;
        a {
            color: $main-color-primary;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
    &__button {
        height: 50px;
        
    }
    &.show {
        #{ $this }__ball {
            background-color: $main-color-primary;
            color: #fff;
        }
    }
}

.schema-mobile {
    display: none;
    .schema-step {
        margin-bottom: 20px;
        &__wrap {
            position: relative;
            padding-left: 63px;
            opacity: 1;
            &:after, &:before {
                display: none;
            }
        }
        &__title {
            font-size: calcFluid(20, 24);
            font-weight: calcFluid(25, 30);
        }
        &__text {
            font-size: calcFluid(13, 18);
            font-weight: calcFluid(18, 24);
        }

        &__number {
            top: 16px;
            left: 14px;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 31px;
            height: 31px;
            font-size: 13.6px;
            line-height: 17px;
            color: $main-color-primary;
            background-color: transparent;
            border: 1px solid $main-color-primary;
            border-radius: 50%;
        }
    }
    .schema-last-step {
        &__wrap {
            transform: none;
            opacity: 1;
            max-width: 100%;
            &:after, &:before {
                display: none;
            }
        }
    }
}


@media(max-width: 991px) {
    .section-schema {
        &__header {
            flex-direction: column-reverse;
            align-items: flex-start;
        }
    }
    .schema {
        display: none;
    }
    .schema-mobile {
        display: block;
    }
    .schema-line {
        display: none;
    }
    .schema-step {
        &__ball {
            display: none;
        }
        &__wrap {
            opacity: 1;
            &:after, &:before {
                display: none;
            }
        }
        &__title {
            font-size: calcFluid(14, 20, 320, 991);
            line-height: calcFluid(18, 24, 320, 991);
        }
        &__text {
            font-size: calcFluid(12, 16, 320, 991);
            line-height: calcFluid(16, 20, 320, 991);
        }
    }
}