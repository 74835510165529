.section-text {
    padding: calcFluid(25, 55) 0;
}

.content-text,
.section-text {
    &__wrap {
        h1 {
            @include font-h1;
            margin-bottom: calcFluid(10, 22, 320, 1920);
        }
        h2 {
            @include font-h2;
            padding-top: calcFluid(8, 16);
            margin-bottom: calcFluid(10, 20);
        }
        h3 {
            @include font-h3;
            padding-top: calcFluid(8, 16);
            margin-bottom: calcFluid(6, 10);
        }
        p {
            @include font-p;
            &:not(:last-child) {
                margin-bottom: calcFluid(7, 14, 320, 1920);
            }
        }
        a {
            text-decoration: underline;
            transition:.2s;
            color: $main-color-primary;
            opacity: .8;
            &:visited{
                color: #834AE0;
                opacity: 1;
            }
            &:hover, &:focus {
                opacity: 1;
                color: $main-color-primary;
                text-decoration: none;
            }
        }
        ol {
            padding-left: 20px;
            padding-top: 0;
            margin-bottom: 20px;
            > li {
                @include font-p;
                list-style-type: decimal;
                padding-left: calcFluid(5, 8, 320, 1920);
                &:not(:last-child) {
                    margin-bottom: calcFluid(7, 15);
                }
                &::marker {
                    color: $main-color-primary;
                }
            }
            ul{
                li:last-child{
                    padding-bottom: 0;
                }
            }
        }
        ul {
            margin-left: 0;
            padding-top: 0;
            margin-bottom: calcFluid(15, 30);
            > li {
                @include font-p;
                position: relative;
                display: block;
                padding-left: calcFluid(15, 20, 320, 1920);
                &:not(:last-child) {
                    margin-bottom: calcFluid(7, 15);
                }
                &:before {
                    content: '';
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background-color: $main-color-primary;
                    display: block;
                    position: absolute;
                    top: 10px;
                    left: 4px;
                }
            }
        }
        picture {
            display: block;
            &.alignleft {
                float: left;
                margin-right: 15px;
            }
            &.aligncenter {
                margin: 0 auto;
            }
            &.alignright {
                float: right;
                margin-left: 15px;
            }
        }
        img {
            max-width: 100%;
            width: auto !important;
            height: auto;
            object-fit: contain;
            &.alignleft {
                margin-right: auto;
            }
            &.aligncenter {
                margin: 0 auto;
            }
            &.alignright {
                margin-left: auto;
            }
        }
		table {
            margin-top: 20px;
			thead {
				tr {
					th, td {
						@include font-15;
						font-weight: 700;
						color: $main-color-primary;
						padding: calcFluid(6, 12) calcFluid(10, 20) calcFluid(7, 14);
						text-align: start;
						vertical-align: text-top;
						font-weight: 700;
					}
				}
				& + tbody {
					tr {
						&:first-child {
							background-color: transparent;
							th, td {
								font-weight: 400;
							}
						}
					}
				}
			}
			tbody {
				tr {
					&:not(:first-child):not(:last-child) {
						border-bottom: 1px solid #E7E7E7;
					}
					&:first-child {
						th, td {
                            font-weight: 700;
                            border-bottom-color: $main-color-primary;
						}
					}
				}
				th, td {
					@include font-15;
					padding: calcFluid(6, 12) calcFluid(10, 20) calcFluid(7, 14);
					border: 1px solid #E7E7E7;
					vertical-align: text-top;
				}
			}
		}
    }
}