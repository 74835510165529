.section-aplication-form {
    padding: calcFluid(25, 55) 0;
    &__wrap {
        display: flex;
    }
    &__left {
        width: 100%;
        padding-top: calcFluid(0, 100);
    }
    &__right {
        width: calcFluid(400, 570, 768, 1920);
        margin-left: 30px;
        flex-shrink: 0;
    }
    &__title {
        @include font-32;
        font-weight: bold;
        color: var(--color-theme_text);
        margin-bottom: 30px;
    }
    &__arrow {

        display: block;
        margin-left: auto;
        max-width: 50%;

        path{

            &:first-child{

                stroke: #333333;

            }

            &:nth-child(2){

                stroke: #333333;
                fill: #333333;

            }

        }

    }
}

.aplication-form {
    background-color: $color-theme;
    border: 1px solid #D6D6D6;
    border-radius: 5px;
    overflow: hidden;
    &__container {
        padding: 0 calcFluid(15, 40);
        width: 100%;
    }
    &__head {
        padding: calcFluid(12, 23) 0 calcFluid(10, 18);
    }
    &__title {
        padding: calcFluid(7, 15) 0 calcFluid(10, 20);
        background-color: #F5F5F5;
        font-size: calcFluid(17, 24);
        line-height: calcFluid(20, 30);
    }
    &__body {
        padding: calcFluid(10, 20) 0 calcFluid(25, 55);
    }
    &__nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.aplication-form-step {
    display: none;
    &.active {
        display: block;
    }
    &__title {
        @include font-20;
        font-weight: 500;
        color: #333;
        margin-bottom: calcFluid(15, 22);
    }
    &__answers {
        margin-bottom: calcFluid(15, 27);
    }
    &__button {
        width: 270px;
        height: 50px;
    }
    &__submit {
        width: 100%;
        height: 50px;
        margin-bottom: calcFluid(5, 10);
    }
    &__field {
        width: 100%;
        margin-bottom: calcFluid(7, 12);
    }
    &__desc {
        @include font-16;
        color: #333;
        margin-bottom: 17px;
    }
}

.aplication-form-step-answer {
    display: flex;
    cursor: pointer;
    $this:&;
    &:not(:last-child) {
        margin-bottom: calcFluid(7, 14);
    }
    &__input {
        display: none;
        &:not([type=checkbox]):checked + #{ $this }__check {
            border-width: calcFluid(4, 6);
            border-color: $main-color-primary;
        }
        &[type=checkbox] {
            & + #{ $this }__check {
                border-radius: 0;
                position: relative;
                &:after {
                    content: "";
                    position: absolute;
                    top: 2px;
                    left: 2px;
                    right: 2px;
                    bottom: 2px;
                    background-color: $main-color-primary;
                    opacity: 0;
                }
            }
            &:checked + #{ $this }__check {
                border-color: $main-color-primary;
                &:after {
                    opacity: 1;
                }
            }
        }
    }
    &__check {
        background: #fff;
        border: 1px solid #C4C4C4;
        transition: border .3s;
        width: calcFluid(14, 22);
        height: calcFluid(14, 22);
        display: block;
        margin-right: 10px;
        flex-shrink: 0;
        border-radius: 50%;
    }
    &__text {
        @include font-16;
        color: #333;
    }
}

.aplication-form-back {
    display: none;
    align-items: center;
    $this:&;
    &__svg {
        display: block;
        width: 7px;
        height: 10px;
        margin-right: 10px;
        fill: $main-color-secondary;
    }
    &__text {
        @include font-16;
        font-weight: 500;
        color: $main-color-secondary;
        text-decoration-line: underline;
    }
    &:hover {
        #{ $this }__text {
            text-decoration: none;
        }
    }
}



@media(max-width: 767px) {
    .section-aplication-form {
        &__wrap {
            flex-direction: column;
        }
        &__left {
            margin-bottom: 30px;
        }
        &__title {
            margin-bottom: 0;
        }
        &__right {
            width: calcFluid(290, 400, 320, 767);
            margin: auto;
        }
        &__arrow {
            display: none;
        }
        &__title {
            br {
                display: none;
            }
        }
    }
}