.form-event {
	position: relative;
	&.loading {
		.loading-animate-wrap {
			display: flex;
		}
	}
}

.loading-animate-wrap {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(#fff, .8);
	z-index: 2;
	display: none;
	align-items: center;
    justify-content: center;
}

.loading-animate {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 45px;
	height: 16px;
	position: relative;
	margin: auto;
	&__sqr {
		width: 16px;
		height: 16px;
		border-radius: 2px;
		background-color: $main-color-secondary;
		opacity: 0.8;
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto;
		&--1 {
			animation: loading-animate1 1s infinite;
			left: 0;
		}
		
		&--2 {
			animation: loading-animate2 1s infinite;
			left: 0;
			right: 0;
		}
		
		&--3 {
			animation: loading-animate3 1s infinite;
			right: 0;
		}
	}
}




@keyframes loading-animate1 {
	0% {
		transform: scale(1) translateX(-3px);
	}

	25% {
		transform: scale(0.6);
	}

	50% {
		transform: scale(0.6);
	}

	75% {
		transform: scale(0.6);
	}

	100% {
		transform: scale(1) translateX(-3px);
	}
}

@keyframes loading-animate2 {
	0% {
		transform: scale(0.6);
	}

	25% {
		transform: scale(1);
	}

	50% {
		transform: scale(0.6);
	}

	75% {
		transform: scale(0.6);
	}

	100% {
		transform: scale(0.6);
	}
}

@keyframes loading-animate3 {
	0% {
		transform: scale(0.6);
	}

	25% {
		transform: scale(0.6);
	}

	50% {
		transform: scale(1) translateX(3px);
	}

	75% {
		transform: scale(0.6);
	}

	100% {
		transform: scale(0.6);
	}
}