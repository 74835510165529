.section-form {
    background-color: var(--bg-color) !important;
    $this:&;
    &__text-block {
        margin-bottom: 20px;
    }
    &__title {
        @include font-32;
        font-weight: 700;
        color: var(--text-color);
        display: block;
        margin-bottom: 15px;
    }
    &__desc {
        @include font-16;
        color: var(--text-color) !important;
    }
    .form-event__success {
        color: var(--text-color) !important;
    }
    .loading-animate-wrap {
        background-color: transparent;
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: var(--bg-color) !important;
            opacity: .8;
            z-index: -1;
        }
    }
    .loading-animate {
        &__sqr {
            background-color: $main-color-primary;
        }
    }
    .field {
        &__input {
            background-color: transparent;
            color: var(--text-color) !important;
            border-color: var(--text-color);
        }
        &__text {
            color: var(--text-color) !important;
        }
        &.active .field__text, &:hover .field__text {
            color: var(--text-color) !important;
        }
    }
    .personal {
        color: var(--text-color) !important;
        > a {
            color: var(--text-color) !important;
        }
    }
    &__wrap {
        display: flex;
        margin: 0 -15px;
        padding: 0 0 0 calcFluid(0, 100, 991, 1920);
    }
    &__col {
        padding: 30px 0;
        margin: 0 15px;
        position: relative;
        display: flex;
        flex-direction: column;
        &--50 {
            width: calc(50% - 30px);
        }
        &--40 {
            width: calc(40% - 30px);
        }
        &--33 {
            width: calc(33% - 30px);
        }
        &--20 {
            width: calc(20% - 30px);
        }
    }
    &__img {
        display: block;
        width: 100%;
        margin: auto;
    }
    &--one {
    }
    &--two {
        #{ $this }__img {
            position: absolute;
            bottom: 0;
            right: 0;
            width: auto;
            max-height: 100%;
        }
        #{ $this }__col {
            padding: 45px 0;
        }
    }
    &--three {
        position: relative;
        z-index: 0;
        #{ $this }__img {
            position: absolute;
            top: 0;
            left: 50%;
            right: 0;
            bottom: 0;
            z-index: -1;
            height: 100%;
            width: 50%;
            object-fit: cover;
        }
        #{ $this }__wrap {
            padding: 0;
        }
        .form {
            max-width: 470px;
        }
    }
    &--four {
        position: relative;
        z-index: 0;
        padding: calcFluid(25, 50) 0;
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            background-color: var(--bg-color) !important;
            opacity: 0.8;
        }
        #{ $this }__img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -2;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        #{ $this }__wrap {
            padding: 0;
        }
        .form {
            max-width: 470px;
            margin: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 0;
        }
        .loading-animate-wrap {
            &:after {
                background-color: transparent;
            }
        }
    }
}

.form {
    padding: 20px 0;
    &__wrap {
        max-width: calcFluid(270, 370);
    }
    .field {
        margin-bottom: 18px;
    }
    &__button {
        width: 100%;
        height: 50px;
        margin-bottom: 10px;
        .button-white__text {
            color: var(--bg-color);
        }
    }
}



@media(max-width: 575px) {
    .section-form {
        $this:&;
        &__wrap {
            flex-direction: column;
            margin: 0;
            padding: 25 0 0;
        }
        &__col {
            margin: 0;
            padding: 0;
            &--50 {
                width: 100%;
            }
            &--40 {
                width: 100%;
            }
            &--33 {
                width: 100%;
            }
            &--20 {
                width: 100%;
                order: 10;
            }
        }
        &--two {
            padding: 0;
            #{ $this }__col {
                padding: 0;
            }
            #{ $this }__img {
                position: static;
                max-width: 100%;
            }
            #{ $this }__wrap {
                padding-bottom: 0;
            }
        }
        &--three {
            .section-form {
                &__img {
                    position: static;
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
    .form {
        padding-top: 30px;
    }
}