@font-face {
    font-family: 'Roboto';
    src: url('fonts/roboto-regular.woff2') format('woff2'),
         url('fonts/roboto-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
	font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('fonts/roboto-medium.woff2') format('woff2'),
         url('fonts/roboto-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
	font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('fonts/roboto-bold.woff2') format('woff2'),
         url('fonts/roboto-bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
	font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('fonts/roboto-black.woff2') format('woff2'),
         url('fonts/roboto-black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
	font-display: swap;
}