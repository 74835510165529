.section-quote {
    background-color: var(--bg-color);
    &--two {
        padding: calcFluid(30, 120) 0;
    }
    &__wrap {
        display: flex;
        padding: 0 calcFluid(0, 75);
    }
    &__left {
        max-width: 40%;
        display: flex;
    }
    &__right {
        padding-left: calcFluid(30, 75, 767, 1920);
        padding-top: 30px;
        padding-bottom: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &__img {
        display: block;
        margin-top: auto;
        max-height: 100%;
        width: 100%;
    }
    &__name {
        @include font-32;
        font-weight: 700;
        color: #333;
        display: block;
        margin-bottom: calcFluid(5, 10);
    }
    &__order {
        @include font-16;
        color: #333;
        display: block;
        margin-bottom: calcFluid(10, 20);
    }
    &__text {
        @include font-20;
        color: #333;
        max-width: 450px;
        position: relative;
        z-index: 0;
    }
}

.quote {
    max-width: 800px;
    text-align: center;
    margin: auto;
    position: relative;
    z-index: 0;
    &__name {
        @include font-22;
        font-weight: 700;
        color: var(--color-theme_text);
    }
    &__text {
        @include font-26;
        color: var(--color-theme_text);
        display: block;
        margin-bottom: calcFluid(10, 20);
    }
}

.quote-svg {
    width: calcFluid(39, 72);
    height: calcFluid(30, 60);
    position: absolute;
    z-index: -1;
    fill: var(--svg-color);
    &--left {
        top: calcFluid(-5, -10);
        left: calcFluid(-10, -65);
    }
    &--right {
        bottom: calcFluid(-5, -10);
        right: calcFluid(-10, -40);
        transform: rotate(180deg);
    }
}


@media(max-width: 575px) {
    .section-quote {
        padding-bottom: 30px;
        &__wrap {
            flex-direction: column;
        }
        &__left {
            max-width: 60%;
            margin-bottom: 20px;
        }
        &__right {
            padding: 0;
        }
        &__img {
            max-width: 200px;
        }
    }
}