.vertical-title {
    position: absolute;
    left: calcFluid(-50, -80, 1200, 1920);
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    max-height: 230px;
    display: flex;
    align-items: center;
    top: 0;
    // bottom: 0;
    margin: auto;
    &__text {
        @include font-12;
        color: var(--color-theme_text);
        flex-shrink: 0;
        margin-top: 20px;
    }
    &__line {
        width: 1px;
        flex-grow: 1;
        background-color: $main-color-secondary;
        position: relative;
        height: 140px;
        max-height: 100%;    
        &:after {
            content: "";
            position: absolute;
            bottom: -4px;
            left: -4px;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background-color: $main-color-secondary;
        }
    }
}

@media(max-width: 767px) {
    .vertical-title {
        position: static;
        writing-mode: horizontal-tb;
        transform: rotate(0deg);
        margin: 0 0 7px 0;

        &__line {
            height: 9px;
            width: 9px;
            border-radius: 50%;
            flex-grow: 0;
            margin-right: 10px;
            &:after {
                display: none;
            }
        }
        &__text {
            margin-top: 0;
        }
    }
}