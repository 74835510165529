.section-review {
    padding: calcFluid(25, 55) 0;
    overflow: hidden;
    $this:&;
    &__header {
        margin-bottom: calcFluid(10, 30);
        display: flex;
        align-items: center;
    }
    &__title {
        @include font-32;
        font-weight: 700;
        color: var(--color-theme_text);
        margin-right: 15px;
    }
    &--two {
    }
    &__wrap {
        position: relative;
    }
}

.reviews-slider {
    margin: 0 -15px;
    > .review-slide {
        &:not(:first-child) {
            display: none !important;
        }
    }
    .slick-track {
    }
    .slick-list {
        margin-bottom: 25px;
    }
    .slick-slide {
        padding-top: 15px;
        padding-bottom: 20px;
        > div {
            height: 100%;
            padding: 0 15px;
        }
    }
    .slick-dots {
        position: static;
    }
    &--one {
        margin: auto;
        max-width: 1000px;
    }
    &--two {
        margin: auto;
        max-width: 1000px;
        .review-slide {
            display: flex !important;
            align-items: center;
            &__wrap {
                padding: calcFluid(20, 30) calcFluid(30, 80) calcFluid(20, 30) calcFluid(30, 100);
            }
        }
        .slick-track {
            align-items: center;
        }
    }
    &--three {
        margin: 0 -15px;
        .review-slide {
            &__wrap {
                padding: calcFluid(20, 50) calcFluid(30, 45) calcFluid(20, 40);
            }
        }
    }
    &--four {
        .review-slide {
            padding: 0;
            display: flex !important;
            &__wrap {
                max-width: calcFluid(300, 400, 767, 1920);
            }
        }
    }
    &--five {
        margin: auto;
        max-width: 900px;
    }
}

.review-slide {
    background: $color-theme;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, .12);
    border-radius: 5px;
    overflow: hidden;
    height: 100%;
    &__flex {
        display: flex;
    }
    &__wrap {
        padding: calcFluid(20, 55) calcFluid(30, 35);
        width: 100%;
    }
    &__top {
        display: flex;
        align-items: center;
        margin-bottom: calcFluid(10, 20);
    }
    &__img-wrap {
        display: block;
        width: calcFluid(50, 72);
        height: calcFluid(50, 72);
        border-radius: 50%;
        overflow: hidden;
        margin-right: calcFluid(10, 20);
        background-color: #eee;
        flex-shrink: 0;
    }
    &__img {
        display: block;
        width: 100%;
        height: 100%;
    }
    &__name {
        @include font-24;
        font-weight: 700;
        color: #333;
        display: block;
        margin-bottom: 5px;
    }
    &__company {
        @include font-16;
        color: #333;
    }
    &__text {
        &:not(:last-child) {
            margin-bottom: calcFluid(20, 40);
        }
        p {
            @include font-16;
            color: #333;
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }
    &__scan {
        display: block;
        width: 220px;
        max-width: 100%;
        height: auto;
        flex-shrink: 0;
        margin-left: calcFluid(30, 100, 992, 1920);
        overflow: hidden;
        position: relative;
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: $whiteZoomIcon;
            background-size: 20px 20px;
            background-position: 90% 15%;
            background-repeat: no-repeat;
            transition: .3s;
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $main-color-primary;
            opacity: 0;
            transition: .3s;
        }
        &:hover {
            &:after {
                background-position: 50% 50%;
                background-size: 35px 35px;
            }
            &:before {
                opacity: .5;
            }
        }
    }
    &__scan-img {
        display: block;
        width: 100%;
        height: 100%;
    }
}

.review-slide-link {
    display: flex;
    align-items: center;
    $this:&;
    &__svg {
        width: 19px;
        height: 24px;
        margin-right: 15px;
        fill: $main-color-primary;
        transition: .3s;
    }
    &__text {
        @include font-15;
        font-weight: 700;
        color: $main-color-primary;
        transition: .3s;
    }
    &:hover {
        #{ $this }__svg {
            fill: $main-color-secondary;
        }
        #{ $this }__text {
            color: $main-color-secondary;
        }
    }
}

.reviews-slider-arrows {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    pointer-events: none;
    $this:&;
    &__arrow {
        width: 30px;
        height: 60px;
        background: #fff;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .15s;
        pointer-events: all;
        &.next {
            margin-right: calcFluid(-10, 0);
            #{ $this }__svg {
                transform: rotate(180deg);
            }
        }
        &.prev {
            margin-left: calcFluid(-10, 0);
        }
        &:focus {
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1) !important;
        }
        &:hover {
            background-color: $main-color-primary;
            transition: .3s;
            #{ $this }__svg {
                fill: #fff;
                transition: .3s;
            }
        }
    }
    &__svg {
        width: 8px;
        height: 12px;
        fill: #333;
        transition: .15s;
    }
}

.media {
    width: 100%;
    min-height: 100%;
    position: relative;
    $this:&;
    &__preview {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        transition: .3s;
    }
    &__iframe {
        width: 100%;
        height: 100%;
        display: block;
    }
    &.played {
        .video-play {
            opacity: 0;
            visibility: hidden;
        }
        #{ $this }__preview {
            opacity: 0;
            visibility: hidden;
        }
    }
}

.video-play {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,.2);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    transition: .3s;
    $this:&;
    &__button {
        display: flex;
        flex-direction: column;
        align-items: center;
        &:hover {
            .video-play__svg {
                .video-play-icon__triangle {
                    display: block;
                }
            }
        }
    }
    &__svg {
        width: calcFluid(50, 74);
        height: calcFluid(50, 74);
        display: block;
        margin-bottom: 10px;
        fill: #fff;

        .video-play-icon__triangle {
            display: none;
        }
    }
    &__text {
        @include font-15;
        font-weight: 700;
        color: #fff;
    }
}


@media(max-width: 1199px) {
    .reviews-slider-arrows {
        display: none;
    }
}



@media(max-width: 991px) {
    .section-partners {
        &__header {
            flex-direction: column-reverse;
            align-items: flex-start;
        }
    }
}



@media(max-width: 767px) {
    .reviews-slider {
        &--four {
            .review-slide {
                flex-direction: column;
                &__wrap {
                    max-width: 100%;
                }
            }
        }
    }
    .review-slide {
        &__flex {
            flex-direction: column;
        }
        &__flex-left {
            margin-bottom: 20px;
        }
        &__scan {
            margin: auto;
        }
    }
    .media {
        flex: 1 1 auto;
        min-height: auto;
    }
}