.toTop {
    position: fixed;
    right: 30px;
    bottom: 30px;
    display: none;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: $main-color-primary;
    border-radius: 5px;
    transition: .3s;
    cursor: pointer;
    z-index: 10;
    &__svg {
        transform: rotate(90deg);
        fill: #fff;
        width: 20px;
        height: 20px;
    }
    &:hover {
        background-color: $main-color-secondary;
    }
    &.active {
        display: flex;
    }
}